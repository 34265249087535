import React, {Component} from "react";
import classes from "./DashboardDevices.module.css";
import PropTypes from "prop-types"
import {getProbeInfos, PROBE_NUMBER} from "../../requests/elastic_search/GetProbeInfos";
import {Loader} from "rsuite";
import Graph, {GraphType} from "../graphs/GraphComponent";
import Interval from "../../requests/elastic_search/Interval";
import {Trans} from "react-i18next";

class Probe extends Component{

    state = {
        isProbeInfoLoading: true,
        probeInfo: undefined,
        probeError: undefined
    }

    async componentDidMount() {
        await this.loadProbe()
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.modacMacAddr !== prevProps.modacMacAddr){
            await this.loadProbe()
        }
    }

    loadProbe = async() => {
        const probeInfoResponse = await getProbeInfos(this.props.modacMacAddr, this.props.probeNumber, this.props.deviceSerial)
        if(probeInfoResponse.error !== undefined){
            this.setState({ isProbeInfoLoading: false, probeError: probeInfoResponse.error })
            return
        }

        this.setState({ isProbeInfoLoading: false, probeInfo:  probeInfoResponse.data === undefined ? undefined : probeInfoResponse.data._source, probeError: undefined })
    }

    render() {

        if(this.state.probeError !== undefined){
            return <div className={classes.DashboardDevicesGroupContent}><label>An error occurred</label></div>
        }

        if(this.state.probeInfo === undefined){
            return <div className={classes.DashboardDevicesGroupContent}><label><Trans>NoDataOnThisProbe</Trans></label></div>
        }

        return (
            <>
                {this.state.isProbeInfoLoading ? <Loader type="Oval" color="#FFFFFF" height={50} width={50}/> :
                    <div className={classes.DashboardDevicesGroupContent}>

                        <div className={classes.DashboardDevicesKeyValueContainer}>
                            <label><Trans>ProbeName</Trans></label>
                            <label>{this.props.probeNumber === PROBE_NUMBER.PROBE_1 ? this.state.probeInfo.p1_name : this.state.probeInfo.p2_name}</label>
                        </div>
                        <div className={classes.DashboardDevicesKeyValueContainer}>
                            <label><Trans>ProbeSerial</Trans></label>
                            <label>{this.props.probeNumber === PROBE_NUMBER.PROBE_1 ? this.state.probeInfo.p1_serial : this.state.probeInfo.p2_serial}</label>
                        </div>

                        <div className={classes.DashboardDevicesKeyValueContainer}>
                            <label><Trans>DetectorInfo</Trans></label>
                            <label>{this.props.probeNumber === PROBE_NUMBER.PROBE_1 ? this.state.probeInfo.p1_info : this.state.probeInfo.p2_info}</label>
                        </div>
                        <div className={classes.DashboardDevicesKeyValueContainer}>
                            <label><Trans>DetectorInfoUnit</Trans></label>
                            <label>{this.props.probeNumber === PROBE_NUMBER.PROBE_1 ? this.state.probeInfo.p1_info_unit : this.state.probeInfo.p2_info_unit}</label>
                        </div>

                        <div style={{
                            display: "inline-block",
                            width: "50%",
                            borderBottom: "1px solid gray",
                            fontWeight: "bold"
                        }}>
                            <label style={{width: "100%", textAlign: "center", display: "block"}}><Trans>Channel</Trans> 1</label>
                        </div>
                        <div style={{
                            display: "inline-block",
                            width: "50%",
                            borderBottom: "1px solid gray",
                            fontWeight: "bold"
                        }}>
                            <label style={{width: "100%", textAlign: "center", display: "block"}}><Trans>Channel</Trans> 2</label>
                        </div>

                        {/*Channel 1*/}
                        <div style={{
                            display: "inline-block",
                            width: "50%",
                            borderRight: "1px solid gray",
                            padding: "10px"
                        }}>
                            {/*<AlarmsGraph/>*/}
                            <Graph deviceSerial={this.props.deviceSerial} graphType={GraphType.PROBE_ALARMS_EVOLUTION} interval={Interval.HOUR} channel={1} modacMacAddr={this.props.modacMacAddr} probe={this.props.probeNumber} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}/>
                            <div className={classes.DashboardDevicesKeyValueContainer}>
                                <label><Trans>Transmitter</Trans></label>
                                <label>{this.props.probeNumber === PROBE_NUMBER.PROBE_1 ? this.state.probeInfo.p1_ch1_trans : this.state.probeInfo.p2_ch1_trans}</label>
                            </div>
                            <div className={classes.DashboardDevicesKeyValueContainer}>
                                <label><Trans>Unit</Trans></label>
                                <label>{this.props.probeNumber === PROBE_NUMBER.PROBE_1 ? this.state.probeInfo.p1_ch1_unit : this.state.probeInfo.p2_ch1_unit}</label>
                            </div>

                            <div className={classes.DashboardDevicesKeyValueContainer}>
                                <label><Trans>CalibrationCoefficient</Trans></label>
                                <label>{this.props.probeNumber === PROBE_NUMBER.PROBE_1 ? this.state.probeInfo.p1_ch1_calib_coeff : this.state.probeInfo.p2_ch1_calib_coeff}</label>
                            </div>

                            <div className={classes.DashboardDevicesKeyValueContainer}>
                                <label><Trans>CalibrationCoefficientUnit</Trans></label>
                                <label>{this.props.probeNumber === PROBE_NUMBER.PROBE_1 ? this.state.probeInfo.p1_ch1_calib_unit : this.state.probeInfo.p2_ch1_calib_unit}</label>
                            </div>
                        </div>

                        {/*Channel 2*/}

                        <div style={{display: "inline-block", width: "50%", padding: "10px"}}>
                            <Graph deviceSerial={this.props.deviceSerial} graphType={GraphType.PROBE_ALARMS_EVOLUTION} interval={Interval.HOUR} channel={2} modacMacAddr={this.props.modacMacAddr} probe={this.props.probeNumber} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}/>
                            <div className={classes.DashboardDevicesKeyValueContainer}>
                                <label><Trans>Transmitter</Trans></label>
                                <label>{this.props.probeNumber === PROBE_NUMBER.PROBE_1 ? this.state.probeInfo.p1_ch2_trans : this.state.probeInfo.p2_ch2_trans}</label>
                            </div>
                            <div className={classes.DashboardDevicesKeyValueContainer}>
                                <label><Trans>Unit</Trans></label>
                                <label>{this.props.probeNumber === PROBE_NUMBER.PROBE_1 ? this.state.probeInfo.p1_ch2_unit : this.state.probeInfo.p2_ch2_unit}</label>
                            </div>

                            <div className={classes.DashboardDevicesKeyValueContainer}>
                                <label><Trans>CalibrationCoefficient</Trans></label>
                                <label>{this.props.probeNumber === PROBE_NUMBER.PROBE_1 ? this.state.probeInfo.p1_ch2_calib_coeff : this.state.probeInfo.p2_ch2_calib_coeff}</label>
                            </div>

                            <div className={classes.DashboardDevicesKeyValueContainer}>
                                <label><Trans>CalibrationCoefficientUnit</Trans></label>
                                <label>{this.props.probeNumber === PROBE_NUMBER.PROBE_1 ? this.state.probeInfo.p1_ch2_calib_unit : this.state.probeInfo.p2_ch2_calib_unit}</label>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

Probe.propTypes = {
    startDatePeriodTimestamp: PropTypes.number,
    endDatePeriodTimestamp: PropTypes.number,
    modacMacAddr: PropTypes.string.isRequired,
    deviceSerial: PropTypes.string.isRequired,
    probeNumber: PropTypes.any

}

export default Probe
