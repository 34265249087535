export const getDataset = (dataParam) => {
    //TODO: handle error
    const buckets = dataParam.aggregations.date.buckets
    const dataset = {
        backgroundColor: "rgba(255,220,3,0.31)",
        borderColor: "rgb(255,222,3)",
        label: "Uptime (sec)",
        data: [],
        lineTension: 0,
    }

    let dates = []
    buckets.forEach(it => {

        const date = new Date(it.key_as_string)
        dates.push(date)

        dataset.data.push({
            x: date,
            // y: (it.uptime.value / 3600).toFixed(0)
            y: it.uptime.value.toFixed(0)
        })
    })

    return {
        datasets: [dataset],
        dates: dates
    }
}
