export const getDataset = (dataParam) => {

    const colors = ["#2196f3", "#FFDE03", "#48b174", "#bc5038", "#000000", "#e876fe", "#4ed7fe", "#fe9700"]
    const buckets = dataParam.aggregations.date.buckets

    let datasets = []
    let dates = []
    let maxValue = 0
    buckets.forEach(it => {
        const date = new Date(it.key_as_string)
        dates.push(date)

        const useragents = it.useragents
        useragents.buckets.forEach(useragent => {
            const key = useragent.key
            let associatedDataset = datasets.find(dataset => { return dataset.label === key})
            if(associatedDataset === undefined){
                associatedDataset = {backgroundColor: colors[datasets.length], label: key, data: []}
                datasets.push(associatedDataset)
            }
            associatedDataset.data.push({
                x: date,
                y: useragent.doc_count
            })
        })
    })

    return {
        datasets: datasets,
        dates: dates,
        maxValue: maxValue
    }
}
