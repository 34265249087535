import decode from "jwt-decode";
import {config} from "../config";



export const login = async(username, password) => {
    try {
        // Get a token from api server using the fetch api
        const res = await _fetch(config.API_URL + '/login', {
            method: "POST",
            body: JSON.stringify({
                username: username,
                password: password
            })
        })

        if(res.success === true){
            await setToken(res.response.token); // Setting the token in localStorage
        }
        return res
    } catch(ex){
        return {
            error: "unknown error"
        }
    }
};

export const onUserClick = () => {
    try {
        localStorage.setItem("last_usr_click", Date.now().toString())
    } catch(ex){
        console.error(ex)
    }
}

export const loggedIn = () => {
    // Checks if there is a saved token and it's still valid
    const token = getToken(); // Getting token from localstorage
    return !!token && !isTokenExpired(token); // handwaiving here
};

export const isTokenExpired = token => {
    try {
        const decoded = decode(token);
        return decoded.exp < new Date().getTime() / 1000
    } catch (err) {
        console.log("expired check failed! Line 41: AuthService.js" + err);
        return true;
    }
};

export const getContentProviders = () => {
    if(loggedIn()){
        const decodedToken = decode(getToken())
        if(decodedToken !== undefined && decodedToken.user !== undefined && decodedToken.user.contentProvider !== undefined){
            return decodedToken.user.contentProvider
        }
    }
    return undefined
}

export const willTokenExpire = () => {
    // If token will expire in less than 10 minutes, we refresh
    const maxMinutesBeforeRefresh = 10
    if(!loggedIn()){
        return true
    }
    try {
        const decoded = decode(getToken());
        const shouldRefreshAt = (decoded.exp) - (60*maxMinutesBeforeRefresh)
        return shouldRefreshAt < (new Date().getTime() / 1000)
    } catch (err) {
        console.log("expired check failed! Line 59: AuthService.js");
        return false;
    }
}

export const getLastUserClickTimestamp = () => {
    try {
        if(localStorage){
            return localStorage.getItem("last_usr_click")
        }
        return undefined
    } catch(ex){
        return undefined
    }
}

export const refreshToken = async() => {
    const res = await _fetch(config.API_URL + '/refreshtoken', {
        method: "POST",
        body: JSON.stringify({
            'fp-token': getToken(),
        })
    })

    if(res.success === true){
        await setToken(res.response.token); // Setting the token in localStorage
    }
    return res
}

export const setToken = async(idToken) => {
    // Saves user token to localStorage
    await localStorage.setItem("id_token", idToken);
};

export const getToken = () => {
    // Retrieves the user token from localStorage
    //alert("get id_token :" + localStorage.getItem("id_token"))
    return localStorage.getItem("id_token");
};

export const logout = () => {
    // Clear user token and profile data from localStorage
    localStorage.removeItem("id_token");
};

export const getConfirm = () => {
    // Using jwt-decode npm package to decode the token
    return decode(getToken());
};

const _fetch = async (url, options) => {
    try {
        // performs api calls sending the required authentication headers
        let headers = {
            "Content-Type": "application/json",
            "x-api-key": config.API_KEY,
        };

        if(loggedIn()){
            headers["fp-token"] = getToken()
        }
        console.log(headers)

        const response = await fetch(url, {
            headers,
            ...options
        });
        // Verify if status is OK, if not, throw error
        if(!_checkStatus(response)){
            return { success: false, error: "Invalid credentials"}
        }

        const jsonResponse = await response.json()
        return { success: true, response: jsonResponse}

    } catch (error) {
        return {
            success: false,
            error: error.message
        }
    }
};

const _checkStatus = (response) => {
    // raises an error in case response status is not a success
    if (response.status >= 200 && response.status < 300) {
        // Success status lies between 200 to 300
        return true
    } else{
        return false
    }
};

