import 'chartjs-plugin-zoom'
import timeGraphZoomDefaultProps from "./TimeGraphZoomDefaultProps";
import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";


export const graphProps = (dates, interval, startDate, endDate) => {
    const minDate = dates[0].getTime();
    const maxDate = dates[dates.length-1].getTime();

    return {
        type: 'line',
        data: {},
        labels: dates,
        options: {
            plugins:{
                zoom: false
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            pan: {
                enabled: true,
                mode: 'x',
            },
            zoom: timeGraphZoomDefaultProps(minDate, maxDate, interval),
            scales: {
                xAxes: [
                    xAxeTimeDefaultProps(minDate, maxDate, interval, startDate, endDate)
                ],
                yAxes: [{
                    ticks: {
                        max: 0,
                        min: -120,
                        stepSize: 20,
                    }
                }]
            }
        }
    }
}
