import React, {Component} from "react"
import classes from "../Dashboard.module.css"

import Probe from "../devices/Probe";
import {getFactories} from "../../requests/FactoriesRequests";
import Loader from "react-loader-spinner";
import {PROBE_NUMBER} from "../../requests/elastic_search/GetProbeInfos";
import Card, {CardProps} from "../card/Card";
import {LevelType} from "../tree/Tree";
import i18n from "../../i18n"
import {Trans} from "react-i18next";

class DashboardEvents extends Component {

    state = {
        isProbe1Expanded: true,
        isProbe2Expanded: true,

        factoriesLoading: false,
        factoriesError: undefined,

        // selectedDevice: undefined, //{}, //TODO: replace with undefined
    }

    async componentDidMount() {
        const factoriesResponse = await getFactories()
        if(factoriesResponse.error !== undefined){
            this.setState({
                factoriesLoading: false,
                factoriesError: factoriesResponse.error
            })
            return
        }
        this.setState({factories: factoriesResponse.factories})

    }

    onLayoutChange = (layout, layouts) => {
        saveToLS("layouts", layouts);
        this.setState({ layouts });
    }

    render() {
        if(this.props.selectedBuilding !== undefined && this.props.selectedBuilding.id !== "building_1"){
            return <label className={classes.DashboardNoNucleotDevicesLabel}><Trans>NoNucleotDevicesAvailableOnThisArea</Trans></label>
        }


        let deviceSerial = undefined
        if(this.props.selectedDevice !== undefined && this.props.selectedDevice.levelType === LevelType.MODAC && this.props.selectedDevice.parent !== undefined){
            deviceSerial = this.props.selectedDevice.parent.id
        }

        return (

            <div className={classes.DashboardEventsContainer}>

                <Card
                    style={{width: "350px", margin: "15px", display: "inline-block"}}
                    title={i18n.t("AlarmsCount")}
                    infoType={CardProps.CONTROLS_WITH_ALARM_COUNT}
                    startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                    endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}
                    deviceSerial={deviceSerial}
                    modacMacAddr={this.props.selectedDevice === undefined ? undefined : this.props.selectedDevice.id}/>

                {this.props.selectedDevice !== undefined ?

                    <Card style={{width: "350px", margin: "15px", display: "inline-block"}}
                          title={i18n.t("ModacAvailability")} infoType={CardProps.AVERAGE_MODAC_AVAILABILITY}
                          startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                          endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}
                          deviceSerial={deviceSerial}
                          modacMacAddr={this.props.selectedDevice === undefined ? undefined : this.props.selectedDevice.id}/>
                    : undefined }

                {this.props.selectedDevice === undefined ? undefined :

                    (this.state.areDeviceInfosLoading && !this.state.factoriesLoading)
                        ?  <Loader type="Oval" color="#4185F4" height={100} width={100}/>
                        :
                        <div className={classes.DashboardEventsGroupContainer}>
                            <div>
                                <div className={classes.DashboardDevicesGroupContainer} style={{width: "calc(100% - 15px)", position: "relative"}}>
                                    <label onClick={() => this.setState({isProbe1Expanded: !this.state.isProbe1Expanded})} style={{cursor: "pointer"}}><Trans>Probe</Trans> 1</label>
                                    <div className={classes.DashboardDevicesTitleSeparator}/>
                                    {this.state.isProbe1Expanded ? <Probe deviceSerial={deviceSerial} modacMacAddr={this.props.selectedDevice.id} probeNumber={PROBE_NUMBER.PROBE_1} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}  />  : undefined}
                                </div>

                                <div className={classes.DashboardDevicesGroupContainer} onClick={() => this.setState({isProbe2Expanded: !this.state.isProbe2Expanded})} style={{width: "calc(100% - 15px)", position: "relative"}}>
                                    <label onClick={() => this.setState({isProbe2Expanded: !this.state.isProbe2Expanded})} style={{cursor: "pointer"}}><Trans>Probe</Trans> 2</label>
                                    <div className={classes.DashboardDevicesTitleSeparator}/>
                                    {this.state.isProbe2Expanded ? <Probe deviceSerial={deviceSerial} modacMacAddr={this.props.selectedDevice.id} probeNumber={PROBE_NUMBER.PROBE_2} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp} /> : undefined}
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

export default DashboardEvents


function saveToLS(key, value) {
    if (localStorage) {
        localStorage.setItem("rgl-7",
            JSON.stringify({
                [key]: value
            })
        );
    }
}
