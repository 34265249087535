import React, {Component} from "react"
import classes from "../Dashboard.module.css"
import '../graphs/custom_graph.less'

import Interval from "../../requests/elastic_search/Interval"

import DashboardDevices from "../devices/DashboardDevices"
import Tabs from "../../navigation/tabs/Tabs"
import {Redirect, Route, Switch} from "react-router-dom";
import '../../../node_modules/react-grid-layout/css/styles.css';

import "../grid_override.css"
import DashboardEvents from "../events/DashboardEvents";

import {subDays} from 'date-fns/fp'
import DashboardMeasures from "../measures/DashboardMeasures";
import Tree, {LevelType} from "../tree/Tree";
import {getFactories} from "../../requests/FactoriesRequests";
import i18n from "../../i18n"
import MeasuresList from "../measures/MeasuresList";

import {Trans} from "react-i18next"
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";

class DashboardOperations extends Component {

    constructor(props) {
        super(props)

        const now = new Date(Date.now())
        const oneDayAgo = subDays(1, now)
        oneDayAgo.setHours(0,0,0,0);

        this.state = {
            isEvolutionOfCountOfPlaybackPerContentTypeBodyLoaded:false,
            interval: Interval.HOUR,

            startDatePeriodTimestamp: oneDayAgo.getTime(),
            endDatePeriodTimestamp: now.getTime(),

            showTree: false,

            tabs: [
                {
                    name: i18n.t("Measures"),
                    path: "/dashboard/operations/measures"
                },
                {
                    name: i18n.t("Events"),
                    path: "/dashboard/operations/events"
                },
                {
                    name: i18n.t("Devices"),
                    path: "/dashboard/operations/devices"
                }],
            selectedTab: undefined,

            selectedArea: undefined,

            currentBreakpoint: undefined
        };

    }

    async componentDidMount() {
        this.updateCurrentTabIfNeeded()

        const factoriesResponse = await getFactories()
        if(factoriesResponse.error !== undefined){
            this.setState({
                factoriesLoading: false,
                factoriesError: factoriesResponse.error
            })
            return
        }
        this.setState({
            factories: factoriesResponse.factories,
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateCurrentTabIfNeeded()
    }

    updateCurrentTabIfNeeded = () => {
        let tab;
        switch(window.location.pathname){
            case "/dashboard/operations/measures": {
                tab = {...this.state.tabs[0]}
                break
            }
            case "/dashboard/operations/events": {
                tab = {...this.state.tabs[1]}
                break
            }
            case "/dashboard/operations/devices": {
                tab = {...this.state.tabs[2]}
                break
            }
            default:
                tab = {...this.state.tabs[0]}
        }
        if(JSON.stringify(this.state.selectedTab) !== JSON.stringify(tab)){
            this.setState({
                selectedTab: tab
            })
        }
    }


    onFilterTap = () => {
        this.setState({areFiltersVisible: !this.state.areFiltersVisible})
    }


    onPeriodChange = (dates) => {
        if(dates.length === 0){
            this.setState({
                startDatePeriodTimestamp: undefined,
                endDatePeriodTimestamp: undefined
            })
        }else{
            const endDate = dates[1].getTime() + (24 * 60 * 60 * 1000) - 1; // "Returns date at 00h00, so we had seconds to get same date at 23h59
            this.setState({
                interval: (endDate - dates[0]) > (1000 * 60 * 60 * 24 * 2) ? Interval.DAY : Interval.HOUR,
                startDatePeriodTimestamp: dates[0].getTime(),
                endDatePeriodTimestamp: endDate
            })
        }
    }

    onIntervalChanged = (interval) => {
        const currentInterval = this.state.interval
        if(interval !== currentInterval) {
            this.setState({interval: interval})
        }
    }

    onTabChange = (selectedTab) => {
        this.setState({selectedTab: selectedTab})
    }


    onSelectArea = (area) => {
        this.setState({selectedArea: area})
    }


    onBreakpointChange = (breakpoint) => {
        this.setState({currentBreakpoint: breakpoint})
    }


    render(){
        return (
            <>
                <Tabs startDatePeriodTimestamp={this.state.startDatePeriodTimestamp} endDatePeriodTimestamp={this.state.endDatePeriodTimestamp} onPeriodChange={this.onPeriodChange} showAreaSelection={window.location.pathname.startsWith("/dashboard/operations/measures")} onIntervalChanged={this.onIntervalChanged} interval={this.state.interval} onShowTreeChange={this.onShowTreeChange} onSelect={this.onTabChange} tabs={this.state.tabs} selectedTab={this.state.selectedTab} onFilterTap={this.onFilterTap} selectedArea={this.state.selectedArea}/>

                {window.location.pathname === "/dashboard/operations/measures/list" ?
                        <div className={classes?.DashboardTabSubMenu}>
                            <NavLink style={{padding: "5px", cursor: "pointer"}} to={"/dashboard/operations/measures"}>
                                <label><FontAwesomeIcon icon={faChevronCircleLeft} color={"#a4a4a4"} /> <Trans>Back</Trans></label>
                            </NavLink>
                            <h1><Trans>MeasuresList</Trans></h1>
                        </div>

                    : undefined
                }

                <div className={classes.DashboardContainer}>

                    {this.state.factories === undefined ? <label>Loading</label> :

                        <div className={classes.DashboardDevicesTreeContainer}>
                            <Tree alreadySelectedNode={this.state.selectedArea} onSelect={this.onSelectArea} data={this.state.factories}/>
                        </div>
                    }


                    <Switch>
                        <Redirect exact from={"/dashboard/operations/"} to={"/dashboard/operations/measures"}/>

                        <Route exact path={"/dashboard/operations/measures/list"} render={ () =>
                            <MeasuresList
                                modacMacAddr={this.state.selectedArea !== undefined && this.state.selectedArea.levelType === LevelType.MODAC ? this.state.selectedArea : undefined}
                                startDate={this.state.startDatePeriodTimestamp}
                                endDate={this.state.endDatePeriodTimestamp}
                                deviceSerial={this.state.selectedArea !== undefined && this.state.selectedArea.levelType === LevelType.SAS ? this.state.selectedArea : undefined}
                                factories={this.state.factories}
                            />
                        } />

                        <Route exact path={"/dashboard/operations/events"} render={ () =>
                            <DashboardEvents
                                selectedBuilding={this.state.selectedArea !== undefined && this.state.selectedArea.levelType === LevelType.BUILDING ? this.state.selectedArea : undefined}
                                selectedDevice={this.state.selectedArea !== undefined && this.state.selectedArea.levelType === LevelType.MODAC ? this.state.selectedArea : undefined}
                                areFiltersVisible={this.state.areFiltersVisible}
                                interval={this.state.interval}
                                startDatePeriodTimestamp={this.state.startDatePeriodTimestamp}
                                endDatePeriodTimestamp={this.state.endDatePeriodTimestamp}/>
                        }/>

                        <Route exact path={"/dashboard/operations/measures"} render={ () =>
                           <DashboardMeasures
                               selectedArea={this.state.selectedArea}
                               interval={this.state.interval}
                               startDatePeriodTimestamp={this.state.startDatePeriodTimestamp}
                               endDatePeriodTimestamp={this.state.endDatePeriodTimestamp}/>
                        }/>


                        <Route exact path={"/dashboard/operations/devices"} render={() =>
                            <DashboardDevices
                                selectedBuilding={this.state.selectedArea !== undefined && this.state.selectedArea.levelType === LevelType.BUILDING ? this.state.selectedArea : undefined}
                                selectedDevice={this.state.selectedArea !== undefined && this.state.selectedArea.levelType === LevelType.MODAC ? this.state.selectedArea : undefined}
                                selectedSas={this.state.selectedArea !== undefined && this.state.selectedArea.levelType === LevelType.SAS ? this.state.selectedArea : undefined}
                                interval={this.state.interval}
                                startDatePeriodTimestamp={this.state.startDatePeriodTimestamp}
                                endDatePeriodTimestamp={this.state.endDatePeriodTimestamp}/>
                        }/>

                    </Switch>


                </div>
            </>
        )
    }
}

export default DashboardOperations;
