export const getDataset = (dataParam) => {
    const buckets = dataParam.aggregations.date.buckets
    const dataset = {
        borderColor: "rgba(28,95,220,0.63)",
        backgroundColor: "rgba(220,4,151,0)",
        label: "RSSI (dBm)",
        data: []
    };

    let dates = []
    buckets.forEach(it => {
        const date = new Date(it.key_as_string)
        dates.push(date)
        dataset.data.push({
            x: date,
            y: Math.round((it.rssi.value + Number.EPSILON) * 100) / 100

        })
    })

    return {
        datasets: [dataset],
        dates: dates,
    }
}
