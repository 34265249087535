import i18n from "../../../i18n"

export const getDataset = (dataParam) => {
    const buckets = dataParam.aggregations.date.buckets
    const dataset = {
        backgroundColor: "rgba(255,220,3,0.71)",
        borderColor: "rgb(255,222,3)",
        label: `${i18n.t("ControlsDuration")} (sec)`,
        data: [],
        categoryPercentage: 1.0,
        barPercentage: 0.90,
    }

    let dates = []
    buckets.forEach(it => {

        const date = new Date(it.key_as_string)
        dates.push(date)

        dataset.data.push({
            x: date,
            y: Math.round((it.average_duration.value + Number.EPSILON) * 100) / 100

        })
    })

    return {
        datasets: [dataset],
        dates: dates
    }
}
