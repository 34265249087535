export const graphProps = (labels) => {
    return {
        type: "pie",
        labels: labels,
        data: {},
        responsive: true,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    title: function(tooltipItem, data) {
                        return "HTTP " + data['labels'][tooltipItem[0]['index']];
                    },
                    label: function(tooltipItem, data) {
                        return data['datasets'][0]['data'][tooltipItem['index']]+'%';
                    }
                }
            }
        }
    }
}
