import axios from "axios"
import {config} from "../config";
import {getAuthBasicToken} from "../helpers/elasticSearchQueryHelper";
import {getToken} from "../helpers/AuthHelper";

const nucleotApi = axios.create({
    baseURL: config.API_URL
})

export const aviorDefaultElasticSearchHeader = {
    "fp-index": "avior-*"
}

export const logstashDefaultElasticSearchHeader = {
    "fp-index": "logstash-*"
}

nucleotApi.interceptors.request.use((axiosConfig) => {
    axiosConfig.headers.Authorization = 'Basic ' + getAuthBasicToken()
    axiosConfig.headers["fp-token"] = getToken()
    axiosConfig.headers["x-api-key"] = config.API_KEY
    return axiosConfig
})

export default nucleotApi
