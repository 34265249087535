import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";
import {PROBE_NUMBER} from "./GetProbeInfos";

const evolutionOfRadioactivityDuringControlsBody = (interval, optionalStartDateTimestamp, optionalEndDateTimestamp, probe, optionalModacMacAddr, optionalDeviceSerial) => {
    let body = {
        "aggs": {
            "date": {
                "date_histogram": {
                    "field": "logdate",
                    "interval": "1m",
                    "time_zone": "Europe/Berlin",
                    "min_doc_count": 0
                },
                "aggs": {
                    "radioactivity": {
                        "avg": {
                            "field": probe === PROBE_NUMBER.PROBE_1 ? "p1_ch1_smooth" : "p2_ch1_smooth"
                        }
                    }
                }
            }
        },
        "size": 1,
        "_source": {
            "includes": probe === PROBE_NUMBER.PROBE_1 ? ["p1_ch1_unit"] : ["p2_ch1_unit"],
            "excludes": []
        },
        "stored_fields": [
            "*"
        ],
        "script_fields": {},
        "docvalue_fields": [
            {
                "field": "@timestamp",
                "format": "date_time"
            },
            {
                "field": "logdate",
                "format": "date_time"
            }
        ],
        "query": {
            "bool": {
                "must": probe === PROBE_NUMBER.PROBE_1 ?
                    [ { "match_phrase": { "p1_measure_status": { "query": "2"} } } ] :
                    [ { "match_phrase": { "p2_measure_status": { "query": "2"} } } ],
                "filter": [],
                "should": [],
                "must_not": []
            }
        }
    }
    adaptQuery(body, interval, optionalStartDateTimestamp, optionalEndDateTimestamp, undefined, undefined, optionalModacMacAddr, optionalDeviceSerial)

    return body;
}

export const getEvolutionOfRadioactivityDuringControls = async(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, probe, optionalModacMacAddr, optionalDeviceSerial) => {
    if(probe !== PROBE_NUMBER.PROBE_1 && probe !== PROBE_NUMBER.PROBE_2){
        return {
            error: "Probe has to be PROBE_NUMBER.PROBE_1 or PROBE_NUMBER.PROBE_2"
        }
    }
    try{
        const query = evolutionOfRadioactivityDuringControlsBody(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, probe, optionalModacMacAddr, optionalDeviceSerial)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })

        return response.data
    } catch (e) {
        return {
            error: e
        }
    }
}
