import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const evolutionOfControlsDuration = (interval, optionalStartDateTimestamp, optionalEndDateTimestamp, optionalModacMacAddr, optionalDeviceSerial) => {
    let body = {
        "aggs": {
            "date": {
                "date_histogram": {
                    "field": "logdate",
                    "interval": "1d",
                    "time_zone": "Europe/Berlin",
                    "min_doc_count": 0
                },
                "aggs": {
                    "average_duration": {
                        "avg": {
                            "field": "p1_measure_duration"
                        }
                    }
                }

            }

        },
        "size": 0,
        "_source": {
            "excludes": []
        },
        "stored_fields": ["*"],
        "script_fields": {},
        "docvalue_fields": [
            {
                "field": "@timestamp",
                "format": "date_time"
            },
            {
                "field": "logdate",
                "format": "date_time"
            }
        ],
        "query": {
            "bool": {
                "must": [
                    {
                        "match_phrase": {
                            "p1_measure_status": {
                                "query": "3"
                            }
                        }
                    },
                    // {
                    //     "match_phrase": {
                    //         "p1_ch1_trans": {
                    //             "query": "BETA"
                    //         }
                    //     }
                    // }
                ],
                "filter": [],
                "should": [],
                "must_not": []
            }
        }
    }
    adaptQuery(body, interval, optionalStartDateTimestamp, optionalEndDateTimestamp, undefined, undefined, optionalModacMacAddr, optionalDeviceSerial)

    return body;
}

export const getEvolutionOfControlsDuration = async(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, optionalModacMacAddr, optionalDeviceSerial) => {
    try{
        const query = evolutionOfControlsDuration(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, optionalModacMacAddr, optionalDeviceSerial)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })
        return response.data
    } catch (e) {
        return {
            error: e
        }
    }
}
