import React, {Component} from "react";
import {
    getConfirm,
    // getLastUserClickTimestamp,
    getToken,
    isTokenExpired,
    loggedIn, logout, refreshToken,
    willTokenExpire
} from "../../helpers/AuthHelper";
import {Redirect} from 'react-router-dom'

/* A higher order component is frequently written as a function that returns a class. */
export default function withAuth(AuthComponent) {

    return class AuthWrapped extends Component {
        state = {
            confirm: null,
            loaded: false,
            refreshTokenFunctionId: null
        };

        /* In the componentDidMount, we would want to do a couple of important tasks in order to verify the current users authentication status
        prior to granting them enterance into the app. */
        componentDidMount() {
            if (!loggedIn()) {
                this.setState({ loaded: true })
            } else {
                /* Try to get confirmation message from the Auth helper. */
                try {
                    const confirm = getConfirm();
                    this.setState({
                        confirm: confirm,
                        loaded: true
                    });
                    const intervalId = setInterval(() => this.refreshTokenIfNeeded(), 60000)
                    this.setState({ refreshTokenFunctionId : intervalId })
                } catch (err) {
                    /* Oh snap! Looks like there's an error so we'll print it out and log the user out for security reasons. */
                    console.log(err);
                    logout();
                    this.setState({ loaded: true })
                }
            }
        }

        componentDidUpdate(prevProps, prevState, snapshot) {
            if(prevState.confirm !== getToken() && !isTokenExpired(getToken())){
                this.setState({confirm: getToken()})
            }
        }

        async refreshTokenIfNeeded(){
            console.log("refreshTokenIfNeeded")
            // const lastUserClickActionTimestamp = getLastUserClickTimestamp()
            // if(lastUserClickActionTimestamp === undefined){
            //     return
            // }

            // if(isTokenExpired()){
            //     logout()
            //     window.location.replace("/")
            //     // return
            // }

            // if(willTokenExpire() &&  (Date.now() - lastUserClickActionTimestamp) < 1000 * 60 * 10){
            if(willTokenExpire()){
                console.log('refresh token')
                const refreshTokenResult = await refreshToken()
                console.log(refreshTokenResult)
            }
        }

        stopRefreshToken(){
            if(this.state.refreshTokenFunctionId){
                clearInterval(this.state.refreshTokenFunctionId)
            }
        }

        render() {
            if (this.state.loaded === true) {
                if (this.state.confirm != null) {
                    return (
                        /* component that is currently being wrapper(App.js) */
                        <AuthComponent
                            history={this.props.history}
                            confirm={this.state.confirm}
                        />
                    );
                } else {
                    return <Redirect to="/login" />
                }
            } else {
                return null;
            }
        }
    };
}
