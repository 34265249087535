import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";


export const getDeviceInfos = async(modacMacAddr, deviceSerial) => {
    if(modacMacAddr === undefined){
        return {
            error: "device serial cannot be undefined"
        }
    }

    try {
        const query = {
            "size": 1,
            "_source": {
                "excludes": ["p1_*", "p2_*"] // probe infos are not included
            },
            "sort": { "logdate": "desc"},
            "query": {
                "bool": {
                    "must": [
                        {
                            "match_phrase": {
                                "modac_macaddr": {
                                    "query": modacMacAddr
                                }
                            }
                        },
                        {
                            "match_phrase": {
                                "DEVICE_SERIAL.keyword": {
                                    "query": deviceSerial
                                }
                            }
                        }
                    ]
                }
            }
        }

        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })

        if(response.data !== undefined && response.data.hits !== undefined && response.data.hits.hits !== undefined  && response.data.hits.hits.length > 0){
            return {
                error: undefined,
                data: response.data.hits.hits[0]
            }
        }
        return {
            error: new Error("no data in response")

        }

    } catch (e) {
        return {
            error: e
        }
    }
}
