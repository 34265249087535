const prod = {
    API_URL: "https://nucleot-api.fast-point.net/prod",
    API_KEY: "fwYOeBzooo4dLU38SEVTh9XDcngxxl8q812vMGKC",
    ELASTIC_SEARCH_ENDPOINT_PATH: '/getelasticdata',
};

const dev = {
    API_URL: "https://nucleot-api.fast-point.net/dev",
    API_KEY: "Q3pW9ZAkEU8YXTZcsDoYf5YQFL99Ijb7qbqqS6c0",
    ELASTIC_SEARCH_ENDPOINT_PATH: '/getelasticdata',

};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;
