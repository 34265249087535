import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";


export const getMeasureUnit = async(modacMacAddr, deviceSerial) => {
    try {
        const query = {
            "size": 1,
            "_source": {
                "includes": ["p1_ch1_unit"]
            },
            "sort": { "logdate": "desc"},
            "query": {
                "bool": {
                    "must": []
                }
            }
        }
        adaptQuery(query, undefined, undefined, undefined, undefined, undefined, modacMacAddr, deviceSerial)

        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })

        if(response.data !== undefined && response.data.hits !== undefined && response.data.hits.hits !== undefined  && response.data.hits.hits.length > 0 && response.data.hits.hits[0]["_source"] !== undefined){
            const unit = response.data.hits.hits[0]["_source"]["p1_ch1_unit"]
            let data = undefined
            switch (unit) {
                case "C_PER_S":{
                    data = "c/s"
                    break
                }
                case "BQEQ_PER_CM2": {
                    data = "Bq/cm²"
                    break;
                }
                default: data = undefined
            }
            return {
                error: undefined,
                data: data
            }
        }
        return {
            error: new Error("no data in response")

        }

    } catch (e) {
        return {
            error: e
        }
    }
}
