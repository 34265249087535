import timeGraphZoomDefaultProps from "./TimeGraphZoomDefaultProps";
import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";

export const graphProps = (dates, interval, startDate, endDate) => {
    const minDate = dates[0].getTime();
    const maxDate = dates[dates.length-1].getTime();

    const xAxes = xAxeTimeDefaultProps(minDate, maxDate, interval, startDate, endDate)
    xAxes.time.maxUnit = "hour"
    xAxes.time.minUnit = "minute"

    return {
        type: "bar",
        labels: dates,
        data: {},
        responsive: true,
        options: {
            responsive: true,
            maintainAspectRatio: false,
            pan: {
                enabled: true,
                mode: 'x',
            },
            zoom: timeGraphZoomDefaultProps(minDate, maxDate, interval),
            scales: {
                xAxes: [
                    xAxes
                ],
                yAxes: [{
                    ticks: {
                        min: 0
                    },
                }]
            }
        }
    }
}
