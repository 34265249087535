import i18n from "../../../i18n"

export const getDataset = (dataParam) => {
    const buckets = dataParam.aggregations.date.buckets
    const dataset = {
        backgroundColor: "rgba(22,255,1,0.31)",
        borderColor: "rgb(0,184,104)",
        label: `${i18n.t("Availability")} (%)`,
        data: []
    }

    let dates = []
    buckets.forEach(it => {
        const date = new Date(it.key_as_string)
        dates.push(date)

        dataset.data.push(
            {
                x: date,
                y: 100-((it.availability.buckets.not_available.doc_count * 100) / it.doc_count).toFixed(2)
            }
        )
    })

    return {
        datasets: [dataset],
        dates: dates
    }
}
