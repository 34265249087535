export const getFactories = async() => {
    try{
        let factory = {
            id: "factory_1",
            name: "Orano - La Hague",
            buildings: []
        };

        for(let i = 1; i<=20; i++){
            const atelier = {
                id:"building_" + i,
                name: "Atelier " + i,
                sas: []
            }

            const numberOfSasInAtelier = (i === 1) ? 2 : 3

            for(let j = 1; j<=numberOfSasInAtelier; j++) {

                const sas = {
                    id: "sas_" + i + j,
                    name: "sas " + i + j,
                    modacs: []
                }

                let numberOfDevicesInSas = 5
                if(i === 1 && j === 1){
                    numberOfDevicesInSas = 1
                }
                if(i === 1 && j === 2){
                    numberOfDevicesInSas = 1
                }
                for(let k = 1; k<=numberOfDevicesInSas; k++) {

                    let modac = {
                        id: "modac_" + i + j+k,
                        name: "modac " + i + j + k,
                        modacs: []
                    }

                    if(i === 1 && j === 1 && k === 1){
                        modac.id = "2462ABB7AB28"
                        modac.name = `modac-${modac.id}`
                        modac.reference = "ORANO-MEDIASPOT-02"
                    }

                    /*if(i === 1 && j === 2 && k === 1){
                        modac.id = "840D8E34D0A0"
                        modac.name = `modac-${modac.id}`
                        modac.reference = "ORANO-MEDIASPOT-03"

                    }*/

                    if(i === 1 && j === 2 && k === 1){
                        modac.id = "2462ABB9A644"
                        modac.name = `modac-${modac.id}`
                        modac.reference = "ORANO-MEDIASPOT-03"
                    }

                    sas.modacs.push(modac)
                }

                if(i === 1 && j === 1){
                    sas.id = "GEWY40300626"
                    sas.name = "sas pilote 1"
                    sas.reference = "ORANO-MEDIASPOT-02"
                }
                if(i === 1 && j === 2){
                    sas.id = "GEWY40300594"
                    sas.name = "sas pilote 2"
                    sas.reference = "ORANO-MEDIASPOT-03"
                }
                atelier.sas.push(sas)
            }
            if(i === 1){
                atelier.name = "Fablab"
            }
            factory.buildings.push(atelier)
        }

        const resp = {factories: []}
        resp.factories.push(factory)
        return resp
    }catch(ex){
        return {
            error: ex.message
        }
    }
}
