import React, {Component} from "react"

import classes from "./DashboardDevices.module.css"
import Graph, {GraphType} from "../graphs/GraphComponent";

import Barcode from "react-barcode"
import {getDeviceInfos} from "../../requests/elastic_search/GetDeviceInfos";
import Loader from "react-loader-spinner";
import {secToText} from "../../helpers/DateHelper";
import Card, {CardProps} from "../card/Card";
import {getFactories} from "../../requests/FactoriesRequests";
import {getMediaspotInfos} from "../../requests/elastic_search/GetMediaspotInfo";
import i18n from "../../i18n"
import {Trans} from "react-i18next";

class DashboardDevices extends Component {

    state = {
        factoriesLoading: false,
        factoriesError: undefined,

        isProbe1Expanded: false,
        isProbe2Expanded: false,

        areSasInfosLoaded: false,
        areDeviceInfosLoaded: false,
        deviceInfos: undefined,

        numberOfModacs:0
    }

    async componentDidMount() {

        const modacCount = await this.updateModacsCount()
        this.setState({numberOfModacs: modacCount})
        if(this.props.selectedDevice !== undefined){
            this.getModacInfos(this.props.selectedDevice)
        }

        if(this.props.selectedSas !== undefined){
            this.getSasMediaspotInfos(this.props.selectedSas)
        }
    }

    getSasMediaspotInfos = (sas) => {
        this.setState({selectedSas: sas, areSasInfosLoaded: false}, async() => {
            const mediaspotInfos = await getMediaspotInfos(sas.id)
            if(mediaspotInfos.data !== undefined){
                this.setState({areSasInfosLoaded: true, deviceInfos: mediaspotInfos.data._source})
            }
        })
    }

    getModacInfos = (device) => {
        const modacMacAddr = device.id
        const deviceSerial = (device.parent !== undefined) ? device.parent.id : undefined
        this.setState({selectedDevice: modacMacAddr, areDeviceInfosLoaded : false}, async() => {
            const deviceInfos = await getDeviceInfos(modacMacAddr, deviceSerial)
            if(deviceInfos.data !== undefined){
                this.setState({areDeviceInfosLoaded: true, deviceInfos: deviceInfos.data._source})
            }
        })
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.selectedDevice !== prevProps.selectedDevice && this.props.selectedDevice !== undefined){
            this.setState({areSasInfosLoaded: false})
            this.getModacInfos(this.props.selectedDevice)
        }

        if(this.props.selectedSas !== prevProps.selectedSas && this.props.selectedSas !== undefined){
            this.setState({areDeviceInfosLoaded: false})
            this.getSasMediaspotInfos(this.props.selectedSas)
        }

        if(this.props.selectedDevice !== prevProps.selectedDevice ||  this.props.selectedBuilding !== prevProps.selectedBuilding || this.props.selectedSas !== prevProps.selectedSas
            && this.state.numberOfModacs !== prevState.numberOfModacs){
            const modacCount = await this.updateModacsCount()
            this.setState({numberOfModacs: modacCount})
        }
    }

    updateModacsCount = async() => {
        let count = 0
        if(this.props.selectedSas !== undefined){
            this.props.selectedSas.modacs.forEach(it => {
                count++
            })
            return count
        }

        if(this.props.selectedBuilding !== undefined){
            this.props.selectedBuilding.sas.forEach(sas => {
                sas.modacs.forEach(_ => {
                    count++
                })
            })
            return count
        }

        const factories = await getFactories()
        factories.factories[0].buildings.forEach(building => {
            building.sas.forEach(sas => {
                sas.modacs.forEach(_ => {
                    count++
                })
            })
        })
        return count
    }


    render(){
        if(this.props.selectedBuilding !== undefined && this.props.selectedBuilding.id !== "building_1"){
            return <label className={classes.DashboardNoNucleotDevicesLabel}><Trans>NoNucleotDevicesAvailableOnThisArea</Trans></label>
        }

        let selectedSas = this.props.selectedSas
        if(this.props.selectedDevice !== undefined && this.props.selectedDevice.parent !== undefined){
            selectedSas = this.props.selectedDevice.parent
        }

        const deviceSerial = selectedSas !== undefined ? selectedSas.id : undefined
        return (
            <div className={classes.DashboardDevicesContainer}>
                {/* Display number of modac only when no modac selected */}
                {this.props.selectedDevice === undefined ?
                    <>
                        <Card style={{width: "350px", margin: "15px", display: "inline-block"}} title={i18n.t("NumberOfModacs")} value={this.state.numberOfModacs} backgroundColor={"#1F9BD9"} interval={this.state.interval} startDatePeriodTimestamp={this.state.startDatePeriodTimestamp} endDatePeriodTimestamp={this.state.endDatePeriodTimestamp}/>
                        <Card style={{width: "350px", margin: "15px", display: "inline-block"}} infoType={CardProps.ACTIVE_MODACS_COUNT} deviceSerial={deviceSerial} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp} />
                    </> : undefined
                }

                {this.props.selectedDevice !== undefined ?
                    <Card style={{width: "350px", margin: "15px", display: "inline-block"}}
                          infoType={CardProps.AVERAGE_MODAC_AVAILABILITY}
                          startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                          endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}
                          deviceSerial={deviceSerial}
                          modacMacAddr={this.props.selectedDevice === undefined ? undefined : this.props.selectedDevice.id}/>
                : undefined }


                {this.props.selectedSas === undefined ? undefined :
                    (!this.state.areSasInfosLoaded && !this.state.factoriesLoading)
                        ? <Loader type="Oval" color="#4185F4" height={100} width={100}/>
                        :
                        <>
                            {/* MEDIASPOT INFO */}
                            <br />
                            <div className={classes.DashboardDevicesGroupContainer}>

                                <label><Trans>MediaspotInfos</Trans></label>
                                <div className={classes.DashboardDevicesTitleSeparator}/>

                                <div className={classes.DashboardDevicesGroupContent}>
                                    {this.props.selectedSas.reference !== undefined ?
                                        <div className={classes.DashboardDevicesKeyValueContainer}>
                                            <label><Trans>Reference</Trans> :</label>
                                            <label style={{fontFamily: "Monaco"}}>{this.props.selectedSas.reference}</label>
                                        </div>
                                        : undefined}

                                    <div className={classes.DashboardDevicesKeyValueContainer}>
                                        <label><Trans>Serial</Trans></label>
                                        <label>{this.state.deviceInfos["DEVICE_SERIAL"]}</label>
                                    </div>
                                    <div className={classes.DashboardDevicesKeyValueContainer}>
                                        <label>BSSID</label>
                                        <label>{this.state.deviceInfos["modac_bssid"]}</label>
                                    </div>
                                    <div className={classes.DashboardDevicesKeyValueContainer} style={{display: "flow-root"}}>
                                        <label><Trans>HostIP</Trans></label>
                                        <label style={{fontFamily: "Monaco"}}>{this.state.deviceInfos.host !== undefined ? this.state.deviceInfos.host.ip.map(it => {
                                            return <span style={{display: "block", textAlign: "end"}}>{it}</span>
                                        }) : "-"}</label>
                                    </div>
                                    <div className={classes.DashboardDevicesKeyValueContainer}>
                                        <label><Trans>HostMac</Trans></label>
                                        <label style={{fontFamily: "Monaco"}}>{this.state.deviceInfos.host !== undefined ? this.state.deviceInfos.host.mac.map(it => {
                                            return <span style={{display: "block", textAlign: "end"}}>{it}</span>
                                        }) : "-"}</label>
                                    </div>
                                </div>
                            </div>
                        </>

                }

                {this.props.selectedDevice === undefined ? undefined :

                    (!this.state.areDeviceInfosLoaded && !this.state.factoriesLoading)
                        ?  <Loader type="Oval" color="#4185F4" height={100} width={100}/>
                    :
                    <>
                    {/* MODAC INFO */}

                        <div className={classes.DashboardDevicesGroupContainer} style={{width: "calc(100% - 15px)"}}>

                            <label><Trans>AviorIdentification</Trans></label>
                            <div className={classes.DashboardDevicesTitleSeparator}/>

                            <div className={classes.DashboardDevicesGroupContent}>
                                <div style={{textAlign: "center"}}>
                                    <Barcode height={30} value={"025298"}/>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label>AVIOR-2</label>
                                    <label>MD 25298</label>
                                </div>
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>LastCheck</Trans></label>
                                    <label>2020/03/31</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>NextCheck</Trans></label>
                                    <label>2021/03/30</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>ObservationID</Trans></label>
                                    <label>2020-42036</label>
                                </div>
                            </div>
                        </div>

                        {/* INFOS */}

                        <div className={classes.DashboardDevicesGroupContainer}>
                            <label><Trans>ModacInfos</Trans></label>
                            <div className={classes.DashboardDevicesTitleSeparator}/>


                            <div className={classes.DashboardDevicesGroupContent}>
                            {this.props.selectedDevice.reference !== undefined ?
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>Reference</Trans> :</label>
                                    <label>{this.props.selectedDevice.reference}</label>
                                </div>
                                : undefined}

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>SerialNumber</Trans> :</label>
                                    <label>{this.state.deviceInfos.modac_serial}</label>
                                </div>
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>ModacBuildVersion</Trans></label>
                                    <label>{this.state.deviceInfos.modac_build}</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label>Uptime</label>
                                    <label>{secToText(this.state.deviceInfos.modac_uptime)}</label>
                                </div>
                                <br />
                                <label><Trans>FreeRam</Trans> (kB) :</label>
                                <br /><br />
                                <div>
                                    <Graph deviceSerial={deviceSerial} modacMacAddr={this.props.selectedDevice.id} graphType={GraphType.FREE_RAM_EVOLUTION} interval={this.props.interval} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}/>
                                </div>
                            </div>

                        </div>



                        {/* WIFI */}

                        <div className={classes.DashboardDevicesGroupContainer}>
                            <label>Wi-Fi</label>
                            <div className={classes.DashboardDevicesTitleSeparator}/>

                            <div className={classes.DashboardDevicesGroupContent}>
                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>WifiFirmware</Trans></label>
                                    <label>{this.state.deviceInfos.modac_wifi_firmware}</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label>SSID</label>
                                    <label>{this.state.deviceInfos.modac_ssid}</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>MediaspotBSSID</Trans></label>
                                    <label>{this.state.deviceInfos.modac_bssid}</label>
                                </div>

                                <div className={classes.DashboardDevicesKeyValueContainer}>
                                    <label><Trans>IPAddress</Trans></label>
                                    <label>{this.state.deviceInfos.modac_ip}</label>
                                </div>

                                {/*<div className={classes.DashboardDevicesKeyValueContainer}>*/}
                                    {/*<label>&nbsp;</label>*/}
                                    {/*<label>&nbsp;</label>*/}
                                {/*</div>*/}
                                <br />

                                <label>RSSI (dB) :</label>
                                <br /><br />
                                <div>
                                    <Graph deviceSerial={deviceSerial} modacMacAddr={this.props.selectedDevice.id} graphType={GraphType.MODAC_RSSI_EVOLUTION} interval={this.props.interval} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}/>
                                </div>
                            </div>



                        </div>


                        {/* Uptime */}

                        <div className={classes.DashboardDevicesGroupContainer}>
                            <label><Trans>ModacUptime</Trans></label>
                            <div className={classes.DashboardDevicesTitleSeparator}/>
                            <div className={classes.DashboardDevicesGroupContent}>
                                <Graph deviceSerial={deviceSerial} modacMacAddr={this.props.selectedDevice.id} graphType={GraphType.UPTIME_EVOLUTION} interval={this.props.interval} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}/>

                            </div>
                        </div>

                        {/* Availability */}

                        <div className={classes.DashboardDevicesGroupContainer}>
                            <label><Trans>CanberraAvailability</Trans></label>
                            <div className={classes.DashboardDevicesTitleSeparator}/>
                            <div className={classes.DashboardDevicesGroupContent}>
                                <Graph deviceSerial={deviceSerial} modacMacAddr={this.props.selectedDevice.id} graphType={GraphType.MODAC_AVAILABILITY_EVOLUTION} interval={this.props.interval} startDatePeriodTimestamp={this.props.startDatePeriodTimestamp} endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}/>

                            </div>
                        </div>

                    </>

                }
            </div>
        )

    }
}
export default DashboardDevices
