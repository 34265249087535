import Interval from "../requests/elastic_search/Interval";
import {config} from "../config";
import {isToday} from "./DateHelper";

export const getAuthBasicToken = () => {
    return window.btoa(unescape(encodeURIComponent(config.ELASTIC_SEARCH_USERNAME + ":" + config.ELASTIC_SEARCH_PASSWORD)));
}

export const adaptQuery = (query, interval, optionalStartDateTimestamp, optionalEndDateTimestamp, optionalClientKey, optionalMediaspotName, optionalMacAddr, optionalDeviceSerial) => {
    if(interval !== undefined && query.aggs !== undefined && query.aggs.date !== undefined){
        switch (interval) {
            case Interval.DAY: {
                query.aggs.date.date_histogram.interval = "1d";
                break
            }
            case Interval.HOUR: {
                query.aggs.date.date_histogram.interval = "1h";
                break
            }
            case Interval.MONTH: {
                query.aggs.date.date_histogram.interval = "1M";
                break
            }
            case Interval.MINUTE: {
                query.aggs.date.date_histogram.interval = "1m";
                break
            }
            default: {
                query.aggs.date.date_histogram.interval = "1H";
            }
        }
    }

    if(optionalStartDateTimestamp !== undefined || optionalEndDateTimestamp !== undefined){
        const logDateRangeObj = {
            "range": {
                "logdate": {
                    "format": "epoch_millis"
                }
            }
        }


        if(optionalStartDateTimestamp !== undefined){
            logDateRangeObj.range.logdate.gte = optionalStartDateTimestamp
        }

        if(optionalEndDateTimestamp !== undefined){
            if(isToday(new Date(optionalEndDateTimestamp))){
                logDateRangeObj.range.logdate.lte = Date.now()
            }else {
                logDateRangeObj.range.logdate.lte = optionalEndDateTimestamp
            }
        }
        query.query.bool.must.push(logDateRangeObj)
    }


    if(optionalClientKey !== undefined){
        query.query.bool.must.push({
            "match_phrase": {
                "CKEY.keyword": {
                    "query": optionalClientKey
                }
            }
        })
    }

    if(optionalMacAddr !== undefined){
        query.query.bool.must.push({
            "match_phrase": {
                "modac_macaddr": {
                    "query": optionalMacAddr
                }
            }
        })
    }


    if(optionalMediaspotName !== undefined){
        query.query.bool.must.push({
            "match_phrase": {
                "MEDIASPOTNAME.keyword": {
                    "query": optionalMediaspotName
                }
            }
        })
    }

    if(optionalDeviceSerial !== undefined){
        query.query.bool.must.push({
            "match_phrase": {
            "DEVICE_SERIAL.keyword": {
                    "query": optionalDeviceSerial
                }
            }
        })
    }
}
