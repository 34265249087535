import React from "react"
import classes from "./Header.module.css"
import LogoutImage from "../../assets/img/logout.svg"
import {logout} from "../../helpers/AuthHelper";
import {Trans} from "react-i18next"
import i18n from "../../i18n"

import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';

import "./LanguageSelector.css"

const header = (props) => {

    let currentLanguage = i18n.fallbackLng
    // If user language already defined (by language detector)
    if(i18n.language !== undefined){
        currentLanguage = i18n.language
    }

    if(i18n.lng !== undefined){
        currentLanguage = i18n.lng
    }

    if(currentLanguage.toUpperCase() === "EN"){
        currentLanguage = "GB"
    }

    return (
        <div className={classes.HeaderContainer}>

            <ReactFlagsSelect
                className="menu-flags"
                onSelect={(it => {

                i18n.changeLanguage(it.toLowerCase() === "gb" ? "en" :  it.toLowerCase());
                window.location.replace("/")
            })} countries={["FR", "GB"]} customLabels={{"FR": "Français", "GB": "English"}} defaultCountry={currentLanguage.substr(0, 2).toUpperCase()} showSelectedLabel={false} />

            <div className={classes.HeaderLogoutContainer} onClick={() => {
                if (window.confirm(i18n.t("AreYouSureYouWantToLogout"))) {
                    logout()
                    window.location.replace("/")
                }}}>

                <div>
                    <img className={classes.HeaderLogoutButton} alt={"logout"} src={LogoutImage} />
                    <label style={{display:"block"}}><Trans>Logout</Trans></label>
                </div>

            </div>
        </div>
    )
}

export default header;
