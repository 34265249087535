export const getDataset = (dataParam) => {

    const colors = ["#2196f3", "#FFDE03", "#48b174", "#bc5038", "#000000", "#e876fe", "#4ed7fe", "#fe9700", "#00feda", "#b4b4b4", "#8d492d", "#357951", "#79006e"]
    const buckets = dataParam.aggregations.useragents.buckets

    let datasets = [{data:[], backgroundColor: []}]
    let labels = []
    let maxValue = 0
    const totalHits = dataParam.hits.total.value
    buckets.forEach(code => {
        const key = code.key
        const value = code.doc_count
        datasets[0].data.push(((value * 100)/totalHits).toFixed(2))
        datasets[0].backgroundColor.push(colors[datasets[0].data.length])
        labels.push(key)

    })

    return {
        datasets: datasets,
        labels: labels,
        maxValue: maxValue
    }
}
