import nucleotApi, {logstashDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const syncCountBody = (optionalStartDateTimestamp, optionalEndDateTimestamp, deviceSerial) => {
    let body = {
        "size": 0,
        "query": {
            "bool": {
                "must": [
                    {
                        "match_phrase": {
                            "statusCode.keyword": "200"
                        }
                    },
                    {
                        "match_phrase": {
                            "uri.keyword": "/sync"
                        }
                    }
                ],
                "should": [],
                "must_not": []
            }
        }
    }
    adaptQuery(body, undefined, optionalStartDateTimestamp, optionalEndDateTimestamp, undefined, undefined, undefined, deviceSerial)

    return body;
}

export const getSyncCount = async(optionalStartDateTimestamp, optionalEndDateTimestamp, deviceSerial) => {
    try{
        const query = syncCountBody(optionalStartDateTimestamp, optionalEndDateTimestamp, deviceSerial)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: logstashDefaultElasticSearchHeader
        })

        if(response.data !== undefined) {
            return {
                error: undefined,
                data: response.data.hits.total.value
            }
        }

        return {
            error: new Error("no data in response")

        }

    } catch (e) {
        return {
            error: e
        }
    }
}
