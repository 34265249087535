import timeGraphZoomDefaultProps from "./TimeGraphZoomDefaultProps";
import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";

export const graphProps = (dates, interval, startDatePeriodTimestamp, endDatePeriodTimestamp) => {
    const minDate = dates[0].getTime();
    const maxDate = dates[dates.length-1].getTime();

    const xAxeTimeDefaultPropsObj = xAxeTimeDefaultProps(minDate, maxDate, interval, startDatePeriodTimestamp, endDatePeriodTimestamp)
    xAxeTimeDefaultPropsObj.stacked= true

    return {
        type: 'bar',
        data: {},
        labels: dates,
        options: {
            plugins:{
                zoom: false
            },
            responsive: true,
            maintainAspectRatio: false,
            pan: {
                enabled: true,
                mode: 'x',
            },
            zoom: timeGraphZoomDefaultProps(minDate, maxDate, interval),
            scales: {
                xAxes: [
                    // xAxeTimeDefaultProps(minDate, maxDate, interval, startDatePeriodTimestamp, endDatePeriodTimestamp),
                    xAxeTimeDefaultPropsObj
                ],
                yAxes: [{
                    ticks: {
                        min: 0
                    },
                    stacked: true

                }]
            }
        }
    }
}
