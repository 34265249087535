import {Redirect, Route, Switch} from "react-router-dom";
import Header from "./navigation/header/Header";
import NavigationMenu from "./navigation/menu/Menu";
import classes from "./index.module.css";
import DashboardMedias from "./dashboard/medias/DashboardMedias";
import DashboardOperations from "./dashboard/operations/DashboardOperations";

import React from "react";

import withAuth from "./hoc/withAuth/withAuth";

const router = () => {
    return (
        <>
            <Header/>
            <NavigationMenu />
            <div className={classes.RootContainer}>
                <Switch>
                    <Route path="/dashboard/operations" render={() => <DashboardOperations/>} />
                    <Route exact path="/dashboard/medias" render={() => <DashboardMedias/>} />
                    <Redirect from={"/"} to={"/dashboard/operations"} />
                </Switch>
            </div>
        </>
    )
}

export default withAuth(router)
