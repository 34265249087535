import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";

export const graphProps = (dates, interval, maxValue, startDate, endDate) => {
    const minDate = dates[0].getTime();
    const maxDate = dates[dates.length-1].getTime();
    const xAxeTimeDefaultPropsObj = xAxeTimeDefaultProps(minDate, maxDate, interval, startDate, endDate)
    xAxeTimeDefaultPropsObj.stacked= true

    return {
        type: "bar",
        labels: dates,
        data: {},
        responsive: true,
        options: {
            plugins:{
                zoom: false
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                xAxes: [
                    xAxeTimeDefaultPropsObj
                ],
                yAxes: [{
                    ticks: {
                        max: 0,
                        min: maxValue,
                        stepSize: 1,
                    },
                    stacked: true
                }]
            }
        }
    }
}
