import React, {Component} from 'react';
import classes from "./Tree.module.css"
import {faAtom, faBuilding, faChevronDown, faChevronRight, faIndustry} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import PropTypes from 'prop-types';

import {LevelType} from "./Tree";

const getPaddingLeft = (level, type) => {
    let paddingLeft = level * 20;
    if (type === 'file') paddingLeft += 20;
    return paddingLeft;
}

class TreeNode extends Component {

    state = {
        isHover: false
    }

    handleMouseHover = () => {
        this.setState({isHover: !this.state.isHover})
    }

        onRowClick = (event, node) => {
        if((event.target.attributes.id === undefined || event.target.attributes.id.value !== "AddItemButton") && this.props.maxLevel !== this.props.levelType){
            this.props.onToggle(node)
        }
        this.onNodeSelect(node)
    }

    onNodeSelect = (node) => {
        node["levelType"] = this.props.levelType
        node["parent"] = this.props.parentNode
        this.props.onNodeSelect(node)
    }

    render(){
        const { node, getChildNodes, level, levelType, getChildLevelType } = this.props;
        const isSelectedDevice = (this.props.alreadySelectedNode !== undefined && node.id === this.props.alreadySelectedNode.id && node.parent === this.props.alreadySelectedNode.parent)

        return (
            <React.Fragment>
                <div  className={classes.TreeNode} style={{paddingLeft: `${getPaddingLeft(level, node.type)}px`}}
                     onClick={(event) => this.onRowClick(event, node)}
                     onMouseEnter={this.handleMouseHover}
                     onMouseLeave={this.handleMouseHover}>

                    <div className={classes.NodeIcon}>
                        {levelType !== LevelType.MODAC && this.props.maxLevel !== levelType ?  (node.isOpen ? <FontAwesomeIcon icon={faChevronDown}/> : <FontAwesomeIcon icon={faChevronRight}/>) : undefined}

                    </div>

                    <div className={classes.NodeIcon} style={{marginRight: "10px"}}>
                        { levelType === LevelType.FACTORY && <FontAwesomeIcon color={isSelectedDevice ? "#2583e6" : "#575757"} icon={faIndustry}/> }
                        { levelType === LevelType.BUILDING && <FontAwesomeIcon color={isSelectedDevice ? "#2583e6" : "#575757"} icon={faBuilding}/> }
                        { levelType === LevelType.SAS && <FontAwesomeIcon color={isSelectedDevice ? "#2583e6" : "#575757"} icon={faAtom}/> }
                        { levelType === LevelType.MODAC && <label style={{fontFamily: "fastpoint", color:isSelectedDevice ? "#2583e6" : "#575757"}}></label> } {/* not whitespace, it's fastpoint icon */}
                    </div>

                    <label style={isSelectedDevice ? {color: "#2583e6", fontWeight: "bold", cursor: "pointer"} : {cursor: "pointer"}}>{node.name}</label>

                </div>

                { node.isOpen && getChildNodes(node, levelType).map(childNode => {
                    return <TreeNode
                        {...this.props}
                        key={`${node.id}${childNode.id}`}
                        levelType={getChildLevelType(levelType)}
                        parentNode={node}
                        node={childNode}
                        level={level + 1}
                    />
                })}
            </React.Fragment>
        );

    }
}

TreeNode.propTypes = {
    node: PropTypes.object.isRequired,
    getChildNodes: PropTypes.func.isRequired,
    parentNode: PropTypes.object,
    level: PropTypes.number.isRequired,
    levelType: PropTypes.any.isRequired,
    onToggle: PropTypes.func.isRequired,
    onNodeSelect: PropTypes.func.isRequired,
    maxLevel: PropTypes.any
};

TreeNode.defaultProps = {
    level: 0,
};

export default TreeNode;
