import React, {Component} from "react"
import PropTypes from "prop-types"
import Chart from "chart.js";
import 'chartjs-plugin-zoom';
import {Trans} from "react-i18next"
import i18n from "../../i18n"

import {getDataset as getRSSIEvolutionDataset} from "./datasets/RSSIEvolutionDataset";
import {getDataset as getCountOfPlaybackPerContentTypeEvolutionDataset} from "./datasets/CountOfPlaybackPerContentTypeEvolutionDataset";
import {getDataset as getCountOfPlaybackPerDeviceTypeEvolutionDataset} from "./datasets/CountOfPlaybackPerDeviceTypeEvolutionDataset";
import {getDataset as getHTTPCodesDistributionDataset} from "./datasets/HTTPCodesDistributionDataset";
import {getDataset as getBrowsersDistributionDataset} from "./datasets/BrowsersDistributionDataset";
import {getDataset as getControlsDurationRepartitionDataset} from "./datasets/ControlsDurationRepartitionDataset";
import {getDataset as getRadioactivityEvolutionDataset} from "./datasets/RadioactivityEvolutionDataset";
import {getDataset as getControlsDurationEvolutionDataset} from "./datasets/ControlsDurationEvolutionDataset";
import {getDataset as getUptimeEvolutionDataset} from "./datasets/UptimeEvolutionDataset.js";
import {getDataset as getFreeRamEvolutionDataset} from "./datasets/FreeRamEvolutionDataset.js";
import {getDataset as getModacRSSIEvolutionDataset} from "./datasets/ModacRSSIEvolutionDataset";
import {getDataset as getProbeAlarmsEvolutionDatasetDataset} from "./datasets/ProbeAlarmsEvolutionDataset";
import {getDataset as getModacAvailabilityDataset} from "./datasets/ModacAvailabilityEvolutionDataset";
import {getDataset as getHitsEvolutionDataset} from "./datasets/CountOfHitsEvolutionDataset";


import {graphProps as rssiEvolutionGraphProps} from "./graphs_props/GraphEvolutionRSSIProps";
import {graphProps as playbackPerContentTypeEvolutionGraphProps} from "./graphs_props/GraphEvolutionOfCountOfPlaybackPerContentTypeProps";
import {graphProps as playbackPerDeviceTypeEvolutionGraphProps} from "./graphs_props/GraphEvolutionOfCountOfPlaybackPerDeviceTypeProps";
import {graphProps as httpCodesDistributionProps} from "./graphs_props/GraphHTTPCodesDistributionProps";
import {graphProps as browsersDistributionProps} from "./graphs_props/GraphBrowsersDistributionProps";
import {graphProps as repartitionControlsDurationProps} from "./graphs_props/GraphRepartitionControlsDurationProps";
import {graphProps as radioactivityEvolutionProps} from "./graphs_props/GraphEvolutionRadioactivityProps";
import {graphProps as controlsDurationEvolutionProps} from "./graphs_props/GraphEvolutionOfControlsDurationProps";
import {graphProps as uptimeEvolutionProps} from "./graphs_props/GraphEvolutionUptimeProps";
import {graphProps as freeRamEvolutionProps} from "./graphs_props/GraphEvolutionFreeRamProps";
import {graphProps as probeAlarmsEvolutionProps} from "./graphs_props/GraphEvolutionProbeAlarmsProps";
import {graphProps as modacAvailabilityEvolutionProps} from "./graphs_props/GraphEvolutionModacAvailabilityProps";
import {graphProps as hitsEvolutionProps} from "./graphs_props/GraphHitsEvolutionProps";


import {getEvolutionOfRSSI} from "../../requests/elastic_search/GetEvolutionRSSI";
import {getEvolutionOfCountOfPlaybackPerContentType} from "../../requests/elastic_search/GetEvolutionOfCountOfPlaybackPerContentType";
import {getEvolutionRepartition} from "../../requests/elastic_search/GetControlsDurationRepartition";
import {getEvolutionOfRadioactivity} from "../../requests/elastic_search/GetEvolutionRadioactivity";
import {getEvolutionOfControlsDuration} from "../../requests/elastic_search/GetEvolutionOfControlsDuration";
import {getEvolutionOfUptime} from "../../requests/elastic_search/GetEvolutionOfUptime";
import {getEvolutionOfFreeRam} from "../../requests/elastic_search/GetEvolutionOfFreeRam";
import {getEvolutionOfModacRSSI} from "../../requests/elastic_search/GetEvolutionOfModacRSSI";
import {getEvolutionOfProbeAlarms} from "../../requests/elastic_search/GetEvolutionOfProbeAlarms";
import {getEvolutionOfRadioactivityDuringControls} from "../../requests/elastic_search/GetEvolutionRadioactivityLevelDuringControls";
import {getEvolutionOfModacAvailability} from "../../requests/elastic_search/GetModacAvailability";
import {getEvolutionOfHits} from "../../requests/elastic_search/GetEvolutionOfHits";


import classes from "./Graph.module.css"
import Loader from "react-loader-spinner";
import {PROBE_NUMBER} from "../../requests/elastic_search/GetProbeInfos";
import {getEvolutionOfCountOfPlaybackPerDeviceType} from "../../requests/elastic_search/GetEvolutionOfCountOfPlaybackPerDeviceType";
import {getHTTPCodesDistribution} from "../../requests/elastic_search/GetHTTPCodesDistribution";
import {getBrowsersDistribution} from "../../requests/elastic_search/GetBrowsersDistribution";

import moment from "moment"
import "moment/min/locales"

export const GraphType = {
    RSSI_EVOLUTION: "RSSI_EVOLUTION",
    PLAYBACK_COUNT_PER_CONTENT_TYPE_EVOLUTION: "PLAYBACK_COUNT_PER_CONTENT_TYPE_EVOLUTION",
    PLAYBACK_COUNT_PER_DEVICE_TYPE_EVOLUTION: "PLAYBACK_COUNT_PER_DEVICE_TYPE_EVOLUTION",
    HTTP_CODES_DISTRIBUTION: "HTTP_CODES_DISTRIBUTION",
    BROWSERS_DISTRIBUTION: "BROWSERS_DISTRIBUTION",
    HITS_EVOLUTION: "HITS_EVOLUTION",

    RADIOACTIVITY_REPARTITION: "RADIOACTIVITY_REPARTITION",
    RADIOACTIVITY_LEVEL_EVOLUTION: "RADIOACTIVITY_LEVEL_EVOLUTION",
    RADIOACTIVITY_LEVEL_DURING_CONTROLS_EVOLUTION: "RADIOACTIVITY_LEVEL_DURING_CONTROLS_EVOLUTION",
    CONTROLS_DURATION_EVOLUTION: "CONTROLS_DURATION_EVOLUTION",

    UPTIME_EVOLUTION: "UPTIME_EVOLUTION",
    FREE_RAM_EVOLUTION: "FREE_RAM_EVOLUTION",
    MODAC_RSSI_EVOLUTION: "MODAC_RSSI_EVOLUTION",

    MODAC_AVAILABILITY_EVOLUTION: "MODAC_AVAILABILITY_EVOLUTION",

    PROBE_ALARMS_EVOLUTION: "PROBE_ALARMS_EVOLUTION"
}

class Graph extends Component {

    chartRef = React.createRef();

    state = {
        contentLoaded: false,
        data: {},
        error: undefined,
        noData: false
    }

    constructor(props){
        super(props)
        moment.locale(i18n.language)
    }

    async componentDidMount() {
        await this.loadGraph()
        Chart.defaults.global.legend.labels.usePointStyle = true;

    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.interval !== prevProps.interval || this.props.startDatePeriodTimestamp !== prevProps.startDatePeriodTimestamp || this.props.endDatePeriodTimestamp !== prevProps.endDatePeriodTimestamp || this.props.modacMacAddr !== prevProps.modacMacAddr || this.props.deviceSerial !== prevProps.deviceSerial){
            await this.loadGraph()
        }
    }


    loadGraph = async() => {
        this.setState({contentLoaded: false, error: undefined, noData: false}, async () => {
            let data = undefined;
            switch (this.props.graphType) {
                case GraphType.PLAYBACK_COUNT_PER_CONTENT_TYPE_EVOLUTION:{ data = await getEvolutionOfCountOfPlaybackPerContentType(this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.selectedClientKey, this.props.selectedMediaspotName, this.props.deviceSerial); break; }
                case GraphType.PLAYBACK_COUNT_PER_DEVICE_TYPE_EVOLUTION:{ data = await getEvolutionOfCountOfPlaybackPerDeviceType(this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.selectedClientKey, this.props.selectedMediaspotName, this.props.deviceSerial); break; }
                case GraphType.HTTP_CODES_DISTRIBUTION:{ data = await getHTTPCodesDistribution(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.deviceSerial); break; }
                case GraphType.BROWSERS_DISTRIBUTION:{ data = await getBrowsersDistribution(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.deviceSerial); break; }
                case GraphType.RSSI_EVOLUTION:{ data = await getEvolutionOfRSSI(this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.selectedClientKey, this.props.selectedMediaspotName, this.props.deviceSerial); break; }
                case GraphType.RADIOACTIVITY_REPARTITION:{ data = await getEvolutionRepartition(this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.deviceSerial); break; }
                case GraphType.RADIOACTIVITY_LEVEL_DURING_CONTROLS_EVOLUTION:{ data = await getEvolutionOfRadioactivityDuringControls(this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.probe, this.props.modacMacAddr, this.props.deviceSerial); break; }
                case GraphType.RADIOACTIVITY_LEVEL_EVOLUTION:{ data = await getEvolutionOfRadioactivity(this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.deviceSerial); break; }
                case GraphType.CONTROLS_DURATION_EVOLUTION:{ data = await getEvolutionOfControlsDuration(this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.deviceSerial); break; }
                case GraphType.UPTIME_EVOLUTION: { data = await getEvolutionOfUptime(this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.deviceSerial); break; }
                case GraphType.FREE_RAM_EVOLUTION: { data = await getEvolutionOfFreeRam(this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.deviceSerial); break; }
                case GraphType.MODAC_RSSI_EVOLUTION: { data = await getEvolutionOfModacRSSI(this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.deviceSerial); break; }
                case GraphType.PROBE_ALARMS_EVOLUTION: { data = await getEvolutionOfProbeAlarms(this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.channel, this.props.probe, this.props.deviceSerial); break; }
                case GraphType.MODAC_AVAILABILITY_EVOLUTION: { data = await getEvolutionOfModacAvailability(this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.deviceSerial); break; }
                case GraphType.HITS_EVOLUTION: { data = await getEvolutionOfHits(this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp, this.props.modacMacAddr, this.props.deviceSerial); break; }
                default: data = undefined
            }

            if(data === undefined || data.error !== undefined){
                this.setState({error: data === undefined ? new Error("No data response") : data.error})
                return
            }

            this.setState({data: data, error: undefined}, () => {
                this.setGraphData()
            })
        })

    }


    setGraphData = () => {
        let resp = undefined;
        switch (this.props.graphType) {
            case GraphType.RSSI_EVOLUTION:{ resp = getRSSIEvolutionDataset(this.state.data); break;}
            case GraphType.PLAYBACK_COUNT_PER_CONTENT_TYPE_EVOLUTION:{ resp = getCountOfPlaybackPerContentTypeEvolutionDataset(this.state.data); break;}
            case GraphType.PLAYBACK_COUNT_PER_DEVICE_TYPE_EVOLUTION:{ resp = getCountOfPlaybackPerDeviceTypeEvolutionDataset(this.state.data); break;}
            case GraphType.HTTP_CODES_DISTRIBUTION:{ resp = getHTTPCodesDistributionDataset(this.state.data); break;}
            case GraphType.BROWSERS_DISTRIBUTION:{ resp = getBrowsersDistributionDataset(this.state.data); break;}
            case GraphType.RADIOACTIVITY_REPARTITION:{ resp = getControlsDurationRepartitionDataset(this.state.data); break;}
            case GraphType.RADIOACTIVITY_LEVEL_DURING_CONTROLS_EVOLUTION:{ resp = getRadioactivityEvolutionDataset(this.state.data); break;}
            case GraphType.RADIOACTIVITY_LEVEL_EVOLUTION:{ resp = getRadioactivityEvolutionDataset(this.state.data); break;}
            case GraphType.CONTROLS_DURATION_EVOLUTION:{ resp = getControlsDurationEvolutionDataset(this.state.data); break;}
            case GraphType.UPTIME_EVOLUTION:{ resp = getUptimeEvolutionDataset(this.state.data); break;}
            case GraphType.FREE_RAM_EVOLUTION:{ resp = getFreeRamEvolutionDataset(this.state.data); break;}
            case GraphType.MODAC_RSSI_EVOLUTION:{ resp = getModacRSSIEvolutionDataset(this.state.data); break;}
            case GraphType.PROBE_ALARMS_EVOLUTION:{ resp = getProbeAlarmsEvolutionDatasetDataset(this.state.data, this.props.probe, this.props.channel); break;}
            case GraphType.MODAC_AVAILABILITY_EVOLUTION:{ resp = getModacAvailabilityDataset(this.state.data, this.props.interval); break;}
            case GraphType.HITS_EVOLUTION:{ resp = getHitsEvolutionDataset(this.state.data); break;}
            default: resp = undefined
        }
        //TODO: set error if resp is undefined
        this.setChart(this.chartRef, resp.datasets, resp.dates, resp.labels, resp.maxValue)
    }

    setChart = (ref, datasets, dates, labels, maxValue) => {
        const myChartRef = ref.current.getContext("2d");
        if(this.chartGraph){
            this.chartGraph.destroy()
        }
        if(dates !== undefined && dates.length === 0){
            this.setState({noData: true, contentLoaded: true})
            return;
        }

        switch (this.props.graphType) {
            case GraphType.PLAYBACK_COUNT_PER_CONTENT_TYPE_EVOLUTION: { this.chartGraph = new Chart(myChartRef, playbackPerContentTypeEvolutionGraphProps(dates, this.props.interval, maxValue, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp)); break; }
            case GraphType.PLAYBACK_COUNT_PER_DEVICE_TYPE_EVOLUTION: { this.chartGraph = new Chart(myChartRef, playbackPerDeviceTypeEvolutionGraphProps(dates, this.props.interval, maxValue, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp)); break; }
            case GraphType.HTTP_CODES_DISTRIBUTION: { this.chartGraph = new Chart(myChartRef, httpCodesDistributionProps(labels)); break; }
            case GraphType.BROWSERS_DISTRIBUTION: { this.chartGraph = new Chart(myChartRef, browsersDistributionProps(labels)); break; }
            case GraphType.RSSI_EVOLUTION: { this.chartGraph = new Chart(myChartRef, rssiEvolutionGraphProps(dates, this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp)); break; }
            case GraphType.RADIOACTIVITY_REPARTITION: { this.chartGraph = new Chart(myChartRef, repartitionControlsDurationProps(dates, this.props.interval, labels, datasets[0].data)); break; }
            case GraphType.RADIOACTIVITY_LEVEL_DURING_CONTROLS_EVOLUTION: { this.chartGraph = new Chart(myChartRef, radioactivityEvolutionProps(dates, this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp)); break; }
            case GraphType.RADIOACTIVITY_LEVEL_EVOLUTION: { this.chartGraph = new Chart(myChartRef, radioactivityEvolutionProps(dates, this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp)); break; }
            case GraphType.CONTROLS_DURATION_EVOLUTION: { this.chartGraph = new Chart(myChartRef, controlsDurationEvolutionProps(dates, this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp)); break; }
            case GraphType.UPTIME_EVOLUTION: { this.chartGraph = new Chart(myChartRef, uptimeEvolutionProps(dates, this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp)); break; }
            case GraphType.FREE_RAM_EVOLUTION: { this.chartGraph = new Chart(myChartRef, freeRamEvolutionProps(dates, this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp)); break; }
            case GraphType.MODAC_RSSI_EVOLUTION: { this.chartGraph = new Chart(myChartRef, rssiEvolutionGraphProps(dates, this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp)); break; }
            case GraphType.PROBE_ALARMS_EVOLUTION: { this.chartGraph = new Chart(myChartRef, probeAlarmsEvolutionProps(dates, this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp)); break; }
            case GraphType.MODAC_AVAILABILITY_EVOLUTION: { this.chartGraph = new Chart(myChartRef, modacAvailabilityEvolutionProps(dates, this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp)); break; }
            case GraphType.HITS_EVOLUTION: { this.chartGraph = new Chart(myChartRef, hitsEvolutionProps(dates, this.props.interval, this.props.startDatePeriodTimestamp, this.props.endDatePeriodTimestamp)); break; }
            default: this.chartGraph = undefined
        }

        if(this.chartGraph !== undefined){
            this.chartGraph.data.datasets = datasets
            if(labels !== undefined){
                this.chartGraph.data.labels = labels
            }
            this.chartGraph.update();

        }

        this.setState({contentLoaded: true})
    }

    handlePress = (e) => {
        e.stopPropagation();
    }

    render() {
        return (
            <div className={classes.GraphContainer}>
                {this.state.contentLoaded || this.state.error ? undefined :
                    <div className={classes.GraphSpinnerContainer}>
                        <Loader type="Oval" color="#4185F4" height={100} width={100}/>
                    </div>
                }

                {this.state.noData ? <div className={classes.GraphSpinnerContainer}>
                    <label><Trans>NoDataOnThisPeriod</Trans></label>
                </div> : undefined}

                {this.state.error === undefined ? undefined :
                    <div className={classes.GraphSpinnerContainer}>
                        <label>An error occurred during loading...</label>
                        <button onClick={this.loadGraph}>Retry</button>
                    </div>}
                <canvas onMouseDown={e => this.handlePress(e)} ref={this.chartRef}/>
            </div>
        )

    }
}

Graph.propTypes = {
    graphType: PropTypes.oneOf(Object.keys(GraphType)).isRequired,

    interval: PropTypes.any.isRequired,
    selectedClientKey: PropTypes.string,
    selectedMediaspotName: PropTypes.string,
    startDatePeriodTimestamp: PropTypes.number,
    endDatePeriodTimestamp: PropTypes.number,

    modacMacAddr: PropTypes.string,
    deviceSerial: PropTypes.string,

    channel: PropTypes.oneOf([1, 2]),
    probe: PropTypes.oneOf([PROBE_NUMBER.PROBE_1, PROBE_NUMBER.PROBE_2])
}

export default Graph
