export const getDataset = (dataParam) => {

    const buckets = dataParam.aggregations.date.buckets
    const jsonDataset = {backgroundColor: "#2196f3", label: "JSON", data: []}
    const mediasDataset = {backgroundColor: "#FFDE03", label: "Médias", data: []}
    let dates = []
    let maxValue = 0
    buckets.forEach(it => {
        const date = new Date(it.key_as_string)
        dates.push(date)

        if(it.types.buckets.Json.doc_count + it.types.buckets.mp4.doc_count > maxValue){
            maxValue = it.types.buckets.Json.doc_count + it.types.buckets.mp4.doc_count
        }

        jsonDataset.data.push({
            x: date,
            y: it.types.buckets.Json.doc_count
        })

        mediasDataset.data.push({
            x: date,
            y: it.types.buckets.mp4.doc_count
        })
    })

    return {
        datasets: [jsonDataset, mediasDataset],
        dates: dates,
        maxValue: maxValue
    }
}
