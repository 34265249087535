import nucleotApi, {logstashDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";


const browserDistributionBody = (optionalStartDateTimestamp, optionalEndDateTimestamp, deviceSerial) => {
    let body = {

        "aggs": {
            "useragents": {
                "terms": {
                    "field": "useragent.name.keyword",
                    "order": {
                        "_count": "desc"
                    }
                }
            }
        },
        "size": 0,
        "query": {
            "bool": {
                "must": [],
                "filter": [],
                "should": [],
                "must_not": [
                    {
                        "match_phrase": {
                            "service.keyword": {
                                "query": "iot.fastpoint.media"
                            }
                        }
                    }
                ]
            }
        }
    }

    adaptQuery(body, undefined, optionalStartDateTimestamp, optionalEndDateTimestamp, undefined, undefined, undefined, deviceSerial)

    return body;
}

export const getBrowsersDistribution = async(optionalStartDateTimestamp, optionalEndDateTimestamp, deviceSerial) => {
    try{
        const query = browserDistributionBody(optionalStartDateTimestamp, optionalEndDateTimestamp, deviceSerial)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: logstashDefaultElasticSearchHeader
        })

        return response.data
    } catch (e) {
        return {
            error: e
        }
    }
}
