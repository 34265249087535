import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const modacRSSIBody = (interval, optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, deviceSerial) => {
    let body = {
        "aggs": {
            "date": {
                "date_histogram": {
                    "field": "@timestamp",
                    "interval": "1h",
                    "time_zone": "Europe/Berlin",
                    "min_doc_count": 1
                },
                "aggs": {
                    "rssi": {
                        "avg": {
                            "field": "modac_rssi"
                        }
                    }
                }
            }
        },
        "size": 0,
        "_source": {
            "excludes": []
        },
        "stored_fields": [
            "*"
        ],
        "script_fields": {},
        "docvalue_fields": [
            {
                "field": "@timestamp",
                "format": "date_time"
            },
            {
                "field": "logdate",
                "format": "date_time"
            }
        ],
        "query": {
            "bool": {
                "must": []
            }
        }
    }

    adaptQuery(body, interval, optionalStartDateTimestamp, optionalEndDateTimestamp, undefined, undefined, modacMacAddr, deviceSerial)

    return body
}

export const getEvolutionOfModacRSSI = async(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, deviceSerial) => {
    if(modacMacAddr === undefined){
        return {
            error: "modacMacAddr has to be specified"
        }
    }
    if(deviceSerial === undefined){
        return {
            error: "device serial has to be specified"
        }
    }
    try{
        const query = modacRSSIBody(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, modacMacAddr, deviceSerial)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })

        return response.data

    } catch (e) {
        return {
            error: e
        }
    }
}
