import Interval from "../../../requests/elastic_search/Interval";
import {subDays} from "date-fns";

const timeGraphZoomDefaultProps = (minDate, maxDate, interval) => {
    return {
        enabled: true,
        mode: 'x',
        rangeMin: {
            // Format of min pan range depends on scale type
            x: getRangeMin(maxDate, interval),
            y: null
        },
        rangeMax: {
            // Format of max pan range depends on scale type
            x: maxDate,
            y: null
        }
    }
}

const getRangeMin = (date, interval) => {
    switch (interval) {
        case Interval.HOUR:
            return subDays(new Date(date), 1).getTime()
        case Interval.DAY:
            return subDays(new Date(date), 30).getTime()
        case Interval.MONTH:
            return subDays(new Date(date), 365).getTime()
        default:
            return subDays(new Date(date), 30).getTime()
    }
}

export default timeGraphZoomDefaultProps;
