import nucleotApi, {logstashDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const evolutionOfCountOfPlaybackPerContentTypeBody = (interval, optionalStartDateTimestamp, optionalEndDateTimestamp, optionalClientKey, optionalMediaspotName, deviceSerial) => {
    let body = {
        "aggs": {
            "date": {
                "date_histogram": {
                    "field": "logdate",
                    "interval": "",
                    "time_zone": "Europe/Berlin",
                    "min_doc_count": 0
                },
                "aggs": {
                    "types": {
                        "filters": {
                            "filters": {
                                "Json": {
                                    "regexp": {
                                        "uri.keyword": ".*.json*"
                                    }
                                },
                                "mp4": {
                                    "regexp": {
                                        "uri.keyword": ".*.mp4*"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        "size": 0,
        "_source": {
            "excludes": []
        },
        "stored_fields": [
            "*"
        ],
        "script_fields": {},
        "docvalue_fields": [
            {
                "field": "@timestamp",
                "format": "date_time"
            },
            {
                "field": "logdate",
                "format": "date_time"
            }
        ],
        "query": {
            "bool": {
                "must": [
                    {
                        "match_phrase": {
                            "service.keyword": {
                                "query": "orano.fastpoint.media"
                            }
                        }
                    },
                    {
                        "match_phrase": {
                            "statusCode.keyword": {
                                "query": "200"
                            }
                        }
                    },
                ],
                "filter": [],
                "should": [],
                "must_not": []
            }
        }
    }
    adaptQuery(body, interval, optionalStartDateTimestamp, optionalEndDateTimestamp, optionalClientKey, optionalMediaspotName, undefined, deviceSerial)

    return body;
}

export const getEvolutionOfCountOfPlaybackPerContentType = async(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, optionalClientKey, optionalMediaspotName, deviceSerial) => {
    if(optionalMediaspotName !== undefined && optionalClientKey === undefined){
        return {
            error: "mediaspot name can only be specified if clientkey is specified"
        }
    }
    try{
        const query = evolutionOfCountOfPlaybackPerContentTypeBody(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, optionalClientKey, optionalMediaspotName, deviceSerial)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: logstashDefaultElasticSearchHeader
        })

        return response.data
    } catch (e) {
        return {
            error: e
        }
    }
}
