import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        en: {
            translations: {
                NoNucleotDevicesAvailableOnThisArea: "No Nucleot devices available on this area",

                // Date range picker
                SelectPeriod: "Select period",
                Last24Hours: "Last 24 hours",
                Last7Days: "Last 7 days",
                LastMonth: "Last month",
                LastYear: "Last year",

                // Tabs
                Measures: "Measures",
                Events: "Events",
                Devices: "Devices",
                Back: "Back",

                // Interval selector
                PerHour: "Per hour",
                PerDay: "Per day",

                // Cards
                ControlsCount: "Controls count",
                AverageControlsDuration: "Average controls duration",
                AlarmsCount: "Alarms count",
                AlarmsPercentage: "Alarms percentage",
                NumberOfActiveModacs: "Number of active modacs",
                NumberOfModacs: "Number of modacs",
                ModacAvailability: "Availability",
                SynchronizationsCount: "Synchronizations count",
                HTTPErrors: "HTTP errors",
                Details: "Details",

                // Login / Logout
                Logout: "Logout",
                EMAIL: "EMAIL",
                PASSWORD: "PASSWORD",
                LOGIN: "LOG IN",
                NUCLEOT_LOGIN: "NUCLEOT LOGIN",
                AreYouSureYouWantToLogout: "Are you sure you want to log out ?",

                // Menu
                Dashboards: "Dashboards",
                Operations: "Operations",
                Contents: "Contents",

                // Controls duration annotations
                NotCompliant: "Not compliant",
                Correct: "Correct",
                Compliant: "Compliant",
                notCompliants: "not compliants",
                corrects: "corrects",
                compliants: "compliants",

                //Graphs titles, legends
                ControlsDurationRepartition: "Controls duration repartition",
                RadiationLevel: "Radiation level",
                RadiationLevelDuringControls: "Radiation level during controls",
                Duration: "Duration",
                Percent: "Percent",
                NoDataOnThisPeriod: "No data on this period",
                PlaybacksCountPerContentType: "Playbacks count per content type",
                PlaybacksCountPerDeviceType: "Playbacks count per device type",
                HTTPCodesDistribution: "HTTP codes distribution",
                BrowsersDistribution: "Browsers distribution",
                HitsCountEvolution: "Hits count evolution",
                Availability: "Availability",
                Radiation: "Radiation",

                // Tooltips
                Between: "Between",
                ControlsDuration: "Controls duration",

                // Device sas
                MediaspotInfos: "Mediaspot infos",
                Reference: "Reference",
                Serial: "Serial",
                HostIP: "IP addresses",
                HostMac: "Mac addresses",

                // Device Modac
                ModacInfos: "Modac infos",
                AviorIdentification: "Avior identification",
                LastCheck: "Last check",
                NextCheck: "Next check",
                ObservationID: "Observation ID",
                ModacBuildVersion: "Build Version",
                ModacSerial: "Modac serial",
                Uptime: "Uptime",
                FreeRam: "Free RAM",
                WifiFirmware: "Wi-Fi firmware",
                MediaspotBSSID: "Mediaspot BSSID",
                IPAddress: "IP Address",
                ModacUptime: "Modac uptime",
                CanberraAvailability: "AVIOR-2 availability",
                SerialNumber: "Serial number",

                // Probe
                Probe: "Probe",
                Channel: "Channel",
                ProbeName: "Probe name",
                ProbeSerial: "Probe serial",
                DetectorInfo: "Detector info",
                DetectorInfoUnit: "Detector info unit",
                Transmitter: "Transmitter",
                Unit: "Unit",
                CalibrationCoefficient: "Calibration coefficient",
                CalibrationCoefficientUnit: "Calibration coefficient unit",
                NoDataOnThisProbe: "No data on this probe",

                // Alarm levels
                Low: "Low",
                High: "High",
                Saturate: "Saturate",

                // Measures list
                ModacMacAddress: "Modac Mac address",
                Date: "Date",
                ControlDuration: "Control duration",
                MaxMeasure: "Max measure",
                AverageMeasure: "Average measure",
                MeasuresList: "Measures list"
            }
        },
        fr: {
            translations: {
                NoNucleotDevicesAvailableOnThisArea: "Aucun appareil NucleoT disponible dans cette zone",

                // Date range picker
                SelectPeriod: "Selectionnez une période",
                Last24Hours: "Dernières 24h",
                Last7Days: "7 derniers jours",
                LastMonth: "Mois dernier",
                LastYear: "An dernier",

                // Tabs
                Measures: "Mesures",
                Events: "Evènements",
                Devices: "Appareils",
                Back: "Retour",

                // Interval selector
                PerHour: "Par heure",
                PerDay: "Par jour",

                // Cards
                ControlsCount: "Nombre de contrôles",
                AverageControlsDuration: "Durée moyenne des contrôles",
                AlarmsCount: "Nombre d'alarmes",
                AlarmsPercentage: "Pourcentage d'alarmes",
                NumberOfActiveModacs: "Nombre de Modacs actifs",
                NumberOfModacs: "Nombre de Modacs",
                ModacAvailability: "Taux de disponibilité",
                SynchronizationsCount: "Nombre de synchronisations",
                HTTPErrors: "Erreurs HTTP",
                Details: "Détails",

                // Login / Logout
                Logout: "Déconnexion",
                EMAIL: "EMAIL",
                PASSWORD: "MOT DE PASSE",
                LOGIN: "CONNEXION",
                NUCLEOT_LOGIN: "CONNEXION NUCLEOT",
                AreYouSureYouWantToLogout: "Êtes-vous sûre de vouloir vous déconnecter ?",

                //Menu
                Dashboards: "Tableaux de bord",
                Operations: "Exploitation",
                Contents: "Contenus",

                // Controls duration annotations
                NotCompliant: "Non conforme",
                Correct: "Correcte",
                Compliant: "Conforme",
                notCompliants: "non conformes",
                corrects: "correctes",
                compliants: "conformes",

                //Graphs titles, legends
                ControlsDurationRepartition: "Répartition des durées de contrôles",
                RadiationLevel: "Niveau de radiation",
                RadiationLevelDuringControls: "Niveau de radiation pendant les contrôles",
                Duration: "Durée",
                Percent: "Pourcentage",
                NoDataOnThisPeriod: "Pas de données sur cette période",
                PlaybacksCountPerContentType: "Nombre de lectures par types de contenu",
                PlaybacksCountPerDeviceType: "Nombre de lectures par types d'appareil",
                HTTPCodesDistribution: "Distribution des codes HTTP",
                BrowsersDistribution: "Distribution des navigateurs",
                HitsCountEvolution: "Evolution du nombre d'appels API",
                Availability: "Disponibilité",
                Radiation: "Radiation",

                // Tooltips
                Between: "Entre",
                ControlsDuration: "Durée des contrôles",

                // Device sas
                MediaspotInfos: "Informations mediaspot",
                Reference: "Réference",
                Serial: "Numéro de série",
                HostIP: "Adresses IP",
                HostMac: "Adresses Mac",

                // Device Modac
                ModacInfos: "Informations du Modac",
                AviorIdentification: "Identification Avior",
                LastCheck: "Dernière vérification",
                NextCheck: "Prochaine vérification",
                ObservationID: "ID d'observation",
                ModacBuildVersion: "Version du firmware",
                ModacSerial: "Numéro Modac",
                Uptime: "Uptime",
                FreeRam: "Mémoire libre",
                WifiFirmware: "Firmware Wi-Fi",
                MediaspotBSSID: "BSSID Mediaspot",
                IPAddress: "Adresse IP",
                ModacUptime: "Uptime Modac",
                CanberraAvailability: "Disponibilité de l'AVIOR-2",
                SerialNumber: "Numéro de série",

                // Probe
                Probe: "Sonde",
                Channel: "Canal",
                ProbeName: "Nom de la sonde",
                ProbeSerial: "Numéro de la sonde",
                DetectorInfo: "Information détecteur",
                DetectorInfoUnit: "Unité d'information de la sonde",
                Transmitter: "Transmetteur",
                Unit: "Unité",
                CalibrationCoefficient: "Coefficient de calibration",
                CalibrationCoefficientUnit: "Unité du coefficient de calibration",
                NoDataOnThisProbe: "Pas de données sur cette sonde",

                // Alarm levels
                Low: "Bas",
                High: "Haut",
                Saturate: "Saturation",

                // Measures list
                ModacMacAddress: "Adresse Mac Modac",
                Date: "Date",
                ControlDuration: "Durée du contrôle",
                MaxMeasure: "Mesure max",
                AverageMeasure: "Mesure moyenne",
                MeasuresList: "Liste des mesures"
            }
        }
    },
    load: "languageOnly",
    fallbackLng: "en",
    debug: false,
    lng: localStorage.getItem("language"),
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;
