import React, {Component} from 'react';
import PropTypes from 'prop-types';

import TreeNode from './TreeNode';


export const LevelType = {
    FACTORY: "FACTORY",
    BUILDING: "BUILDING",
    SAS: "SAS",
    MODAC: "MODAC",
}


export default class Tree extends Component {



    constructor(props) {
        super(props);
        this.state = {
            nodes: this.props.data
        };
        this.props.data[0]["isOpen"] = true
        this.onNodeSelect(this.props.data[0])
    }

    getChildKeyForLevel = (levelType) => {
        switch (levelType) {
            case LevelType.FACTORY : return "buildings"
            case LevelType.BUILDING : return "sas"
            case LevelType.SAS : return "modacs"
            default: return undefined
        }
    };

    getChildLevelType = (levelType) => {
        switch (levelType) {
            case LevelType.FACTORY : return LevelType.BUILDING
            case LevelType.BUILDING : return LevelType.SAS
            case LevelType.SAS : return LevelType.MODAC
            default: return undefined

        }
    }

    getChildNodes = (node, levelType) => {
        const nodeChildKey = this.getChildKeyForLevel(levelType)
        if(nodeChildKey === undefined){
            return []
        }
        return node[nodeChildKey]
    }

    onToggle = (node) => {
        const { nodes } = this.state;
        if(node["isOpen"] === undefined){
            node["isOpen"] = true
        }else {
            node["isOpen"] = !node["isOpen"]
        }
        this.setState({ nodes });
    }

    onNodeSelect = node => {
        const { onSelect } = this.props;
        onSelect(node);
    }

    render() {
        return (
            <div>
                {this.state.nodes.map(node => {
                    return <TreeNode
                        key={node.id}
                        alreadySelectedNode={this.props.alreadySelectedNode}
                        levelType={LevelType.FACTORY}
                        node={node}
                        getChildLevelType={this.getChildLevelType}
                        getChildNodes={this.getChildNodes}
                        onToggle={this.onToggle}
                        onNodeSelect={this.onNodeSelect}
                        maxLevel={this.props.maxLevel}
                    />
                })
                }

            </div>
        )
    }
}

Tree.propTypes = {
    onSelect: PropTypes.func.isRequired,
    alreadySelectedNode: PropTypes.any,
    maxLevel: PropTypes.any
};
