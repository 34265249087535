import i18n from "../../../i18n"

export const getDataset = (dataParam) => {
    const buckets = dataParam.aggregations.date.buckets
    const dataset = {
        backgroundColor: "rgba(44,84,255,0.31)",
        borderColor: "rgb(70,137,255)",
        label: `${i18n.t("FreeRam")} (Bytes)`,
        data: []
    }

    let dates = []
    buckets.forEach(it => {
        const date = new Date(it.key_as_string)
        dates.push(date)

        dataset.data.push(
            {
                x: date,
                y: it.freemem.value.toFixed(0)
            }
        )
    })

    return {
        datasets: [dataset],
        dates: dates
    }
}
