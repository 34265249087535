export const getMonthAsText = (date) => {
    const month = [];
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";
    return month[date.getMonth()];
}

export const secToText = (sec) => {
    let sec_num = parseInt(sec, 10); // don't forget the second param
    let days   = Math.floor(sec_num / (3600 * 24));
    let hours   = Math.floor(sec_num % (3600*24) / 3600);

    const divisorForMinutes = sec_num % (60 * 60);
    let minutes = Math.floor((divisorForMinutes) / 60)

    const divisorForSeconds = divisorForMinutes % 60;
    let seconds = Math.ceil(divisorForSeconds)

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return (days>0 ? days+'d ' : '') +hours+'h '+minutes+'min '+seconds+"sec";
}

export const secToMin = (sec) => {
    let sec_num = parseInt(sec, 10); // don't forget the second param
    const divisorForMinutes = sec_num % (60 * 60);
    let minutes = Math.floor((divisorForMinutes) / 60)

    const divisorForSeconds = divisorForMinutes % 60;
    let seconds = Math.ceil(divisorForSeconds)

    if (seconds < 10) {seconds = "0"+seconds;}
    return (minutes === 0 ? "" : `${minutes} min `)+seconds+" sec";
}

export const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
}