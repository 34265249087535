import React, {Component} from "react"
import classes from "./Menu.module.css"
import classesMenuItem from "./menuitem/MenuItem.module.css"
import {faChartArea} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Trans} from "react-i18next";

import NucleotLogoText from "../../assets/img/nucleot_icon.svg"


class Menu extends Component {

    state = {
        dashboardsMenuExpanded: true,
    };

    componentDidMount() {
        this.setState({dashboardsMenuExpanded: window.location.pathname.startsWith("/dashboard/")})
    }


    onDashboardMenuClick = () => {
        const dashboardsMenuExpanded = this.state.dashboardsMenuExpanded;
        this.setState({dashboardsMenuExpanded: !dashboardsMenuExpanded})
    }

    onRedirectClick = (isDashboardMenu) => {
        this.setState({dashboardsMenuExpanded: isDashboardMenu})
    }

    render(){
        return (

            <div className={classes.MenuContainer}>


                <img className={classes.HeaderLogo} alt={"Nucleot"} src={NucleotLogoText}/>

                <div className={classes.MenuHeaderSeparator}/>

                <div onClick={this.onDashboardMenuClick} className={[classesMenuItem.MenuItemContainer, classesMenuItem.MenuItemContainerWithSubmenus, (window.location.pathname.startsWith("/dashboard/") || this.state.dashboardsMenuExpanded) ?  classesMenuItem.MenuItemActive : undefined].join(' ')}>
                    <div className={classesMenuItem.SubmenuLink}><FontAwesomeIcon icon={faChartArea}/>&nbsp;&nbsp;<Trans>Dashboards</Trans></div>
                </div>

                {/*{this.state.dashboardsMenuExpanded ?*/}
                    <>
                        <div className={[classesMenuItem.SubmenuItemContainer, window.location.pathname.startsWith("/dashboard/operations") ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                            <Link
                                onClick={() => this.onRedirectClick(true)} to={"/dashboard/operations"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Trans>Operations</Trans></Link>
                        </div>
                        <div className={[classesMenuItem.SubmenuItemContainer, "/dashboard/medias" === window.location.pathname ?  classesMenuItem.SubmenuItemActive : undefined].join(' ')}>
                            <Link onClick={() => this.onRedirectClick(true)} to={"/dashboard/medias"}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Trans>Contents</Trans></Link>
                        </div>
                    </>
                {/*    : undefined*/}
                {/*}*/}

                <label className={classes.MenuAppVersion}>Version {process.env.REACT_APP_VERSION}</label>
            </div>
        )
    }
}

export default Menu;
