import i18n from "../../../i18n"

export const getDataset = (dataParam) => {
    if(dataParam !== undefined && dataParam.aggregations !== undefined && dataParam.aggregations.date !== undefined && dataParam.aggregations.date.buckets !== undefined){
        const buckets = dataParam.aggregations.date.buckets
        const dataset = {
            backgroundColor: "rgba(255,220,3,0.31)",
            borderColor: "rgb(255,222,3)",
            label: `${i18n.t("Radiation")} (c/s)`,
            data: [],
            lineTension: 0,
        }

        let dates = []
        buckets.forEach(it => {

            const date = new Date(it.key_as_string)
            dates.push(date)

            dataset.data.push({
                x: date,
                y: Math.round((it.radioactivity.value + Number.EPSILON) * 100) / 100

            })
        })

        return {
            datasets: [dataset],
            dates: dates
        }
    }

    return {
        datasets: [],
        dates: []
    }
}
