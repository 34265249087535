import nucleotApi, {logstashDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const errorPercentageBody = (optionalStartDateTimestamp, optionalEndDateTimestamp, deviceSerial) => {
    let body = {
        "aggs": {
            "types": {
                "filters": {
                    "filters": {
                        "errors": {
                            "regexp": {
                                "statusCode.keyword": "[4-5][0-9][0-9]"
                            }
                        }
                    }
                }
            }
        },
        "size": 0,
        "query": {
            "bool": {
                "must": [
                    {
                        "match_phrase": {
                            "service.keyword": {
                                "query": "orano.fastpoint.media"
                            }
                        }
                    }
                ],
                "filter": [],
                "should": [],
                "must_not": []
            }
        }
    }
    adaptQuery(body, undefined, optionalStartDateTimestamp, optionalEndDateTimestamp, undefined, undefined, undefined, deviceSerial)

    return body;
}

export const getErrorPercentage = async(optionalStartDateTimestamp, optionalEndDateTimestamp, deviceSerial) => {
    try{
        const query = errorPercentageBody(optionalStartDateTimestamp, optionalEndDateTimestamp, deviceSerial)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: logstashDefaultElasticSearchHeader
        })

        if(response.data !== undefined) {
            const numberOfErrorsHits = response.data.aggregations.types.buckets.errors.doc_count
            const total = response.data.hits.total.value
            let data;
            if(total === 0){
                return 0
            }else {
                data = (numberOfErrorsHits * 100) / total
            }
            return {
                error: undefined,
                data: data
            }
        }

        return {
            error: new Error("no data in response")

        }

    } catch (e) {
        return {
            error: e
        }
    }
}
