import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";
import nucleotApi, {logstashDefaultElasticSearchHeader} from "../axios";

const evolutionOfRSSIBody = (interval, optionalStartDateTimestamp, optionalEndDateTimestamp, optionalClientKey, optionalMediaspotName, deviceSerial) => {
    let body = {
        "aggs": {
            "date": {
                "date_histogram": {
                    "field": "logdate",
                    "interval": "1m",
                    "time_zone": "Europe/Berlin",
                    "min_doc_count": 1
                },
                "aggs": {
                    "rssi": {
                        "avg": {
                            "field": "wifi_rssi"
                        }
                    }
                }
            }
        },
        "size": 0,
        "_source": {
            "excludes": []
        },
        "stored_fields": [
            "*"
        ],
        "script_fields": {},
        "docvalue_fields": [
            {
                "field": "@timestamp",
                "format": "date_time"
            },
            {
                "field": "logdate",
                "format": "date_time"
            }
        ],
        "query": {
            "bool": {
                "must": [
                ],
                "filter": [],
                "should": [],
                "must_not": []
            }
        }
    }
    adaptQuery(body, interval, optionalStartDateTimestamp, optionalEndDateTimestamp, optionalClientKey, optionalMediaspotName, undefined, deviceSerial)

    return body;
}

export const getEvolutionOfRSSI = async(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, optionalClientKey, optionalMediaspotName, deviceSerial) => {
    if(optionalMediaspotName !== undefined && optionalClientKey === undefined){
        return {
            error: "mediaspot name can only be specified if clientkey is specified"
        }
    }
    try{
        const query = evolutionOfRSSIBody(interval, optionalStartDateTimestamp, optionalEndDateTimestamp, optionalClientKey, optionalMediaspotName, deviceSerial)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: logstashDefaultElasticSearchHeader
        })

        return response.data
    } catch (e) {
        return {
            error: e
        }
    }
}
