import React from 'react';
import ReactDOM from 'react-dom';
import Login from "./auth/Login";
import Logout from "./auth/Logout";
import {BrowserRouter, Route} from 'react-router-dom';

import Router from "./Router"
// import {onUserClick} from "./helpers/AuthHelper";
import {I18nextProvider} from "react-i18next";
//@ts-ignore
import i18n from './i18n'

const app =  (
    // <div onClick={onUserClick}>
    <I18nextProvider i18n={ i18n }>

        <BrowserRouter>
            <>
                <Router/>
                <Route exact path="/login" render={() => <Login />} />
                <Route exact path="/logout" render={() => <Logout />} />
            </>
        </BrowserRouter>
    </I18nextProvider>
)

ReactDOM.render(app, document.getElementById('root'));
