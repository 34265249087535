import nucleotApi, {aviorDefaultElasticSearchHeader} from "../axios";
import {config} from "../../config";
import {adaptQuery} from "../../helpers/elasticSearchQueryHelper";

const alarmsCountBody = (optionalStartDateTimestamp, optionalEndDateTimestamp, optionalModacMacAddr, optionalDeviceSerial) => {
    let body = {
        "size": 0,
        "_source": {
            "excludes": []
        },
        "stored_fields": [
            "*"
        ],
        "script_fields": {},
        "docvalue_fields": [
            {
                "field": "@timestamp",
                "format": "date_time"
            },
            {
                "field": "logdate",
                "format": "date_time"
            }
        ],
        "query": {
            "bool": {
                "must": [
                    {
                        "match_phrase": {
                            "modac_rs485_status": {
                                "query": "1"
                            }
                        }
                    }
                ],
                "filter": [],
                "should": [],
                "must_not": [
                    {
                        "match_phrase": {
                            "p1_ch1_alarm.keyword": {
                                "query": "NONE"
                            }
                        }
                    }
                ]
            }
        }
    }
    adaptQuery(body, undefined, optionalStartDateTimestamp, optionalEndDateTimestamp,undefined, undefined, optionalModacMacAddr, optionalDeviceSerial)

    return body;
}

export const getAlarmsCount = async(optionalStartDateTimestamp, optionalEndDateTimestamp, optionalModacMacAddr, optionalDeviceSerial) => {
    try{
        const query = alarmsCountBody(optionalStartDateTimestamp, optionalEndDateTimestamp, optionalModacMacAddr, optionalDeviceSerial)
        const response = await nucleotApi.post(config.API_URL + config.ELASTIC_SEARCH_ENDPOINT_PATH, query, {
            headers: aviorDefaultElasticSearchHeader
        })

        if(response.data !== undefined){
            return {
                error: undefined,
                data: response.data.hits.total.value
            }

        }
        return {
            error: new Error("no data in response")

        }

    } catch (e) {
        return {
            error: e
        }
    }
}
