import React, {Component} from 'react';

import {logout} from '../helpers/AuthHelper'


class Logout extends Component {

    state = {
        isSignoutFinished: false
    }

    async componentWillMount(){
        await logout()
    }

    render() {
        if(this.state.isSignoutFinished){
            // User will be redirected
            return null
        }else{
            return (
                <>
                    {/*<Spinner />*/}
                    <p>Logout in progress</p>
                </>
            )
        }
    }
}

export default Logout;
