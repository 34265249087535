import React, {Component} from "react";
import Card, {CardProps} from "../card/Card";
import {LevelType} from "../tree/Tree";
import classes from "../Dashboard.module.css";
import Graph, {GraphType} from "../graphs/GraphComponent";
import {PROBE_NUMBER} from "../../requests/elastic_search/GetProbeInfos";

import {Responsive, WidthProvider} from "react-grid-layout";
import { Trans } from 'react-i18next'
import {getMeasureUnit} from "../../requests/elastic_search/GetMeasureUnit";
const ResponsiveReactGridLayout = WidthProvider(Responsive);


class DashboardMeasures extends Component {

    constructor(props) {
        super(props)
        const originalLayouts = getFromLS("layouts") || {};

        this.state = {
            layouts: JSON.parse(JSON.stringify(originalLayouts)),
            measuresUnit: undefined
        }
    }

    async componentDidMount() {
        let deviceSerial = undefined;
        if(this.props.selectedArea !== undefined) {
            if (this.props.selectedArea.levelType === LevelType.SAS) {
                deviceSerial = this.props.selectedArea.id
            } else if (this.props.selectedArea.levelType === LevelType.MODAC) {
                deviceSerial = this.props.selectedArea.parent.id
            }
        }
        let modacSerial = undefined;
        if(this.props.selectedArea !== undefined && this.props.selectedArea.levelType === LevelType.MODAC){
            modacSerial = this.props.selectedArea.id
        }

        const measureUnitResponse = await getMeasureUnit(modacSerial, deviceSerial)
        if(measureUnitResponse.data !== undefined){
            this.setState({measuresUnit: measureUnitResponse.data})
        }
    }

    onLayoutChange = (layout, layouts) => {
        saveToLS("layouts", layouts);
        this.setState({ layouts });
    }

    render() {
        if(this.props.selectedArea !== undefined && this.props.selectedArea.levelType === LevelType.BUILDING && this.props.selectedArea.id !== "building_1"){
            return <label className={classes.DashboardNoNucleotDevicesLabel}><Trans>NoNucleotDevicesAvailableOnThisArea</Trans></label>
        }

        let deviceSerial = undefined;
        if(this.props.selectedArea !== undefined) {
            if (this.props.selectedArea.levelType === LevelType.SAS) {
                deviceSerial = this.props.selectedArea.id
            } else if (this.props.selectedArea.levelType === LevelType.MODAC) {
                deviceSerial = this.props.selectedArea.parent.id
            }
        }

        return (
            <div style={{marginTop: "50px"}}>
                <ResponsiveReactGridLayout
                    rowHeight={100}
                    cols={{ lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 }}
                    layouts={this.state.layouts}
                    onLayoutChange={this.onLayoutChange}
                    onBreakpointChange={this.onBreakpointChange}
                >

                    <div key={"nb_controls_count"} data-grid={{ w: 3, h: 1, x: 0, y: 0, minW: 2, maxH:1 }}>

                        <Card
                            detailsLink={"/dashboard/operations/measures/list"}
                            style={{textDecoration: "none"}}
                            infoType={CardProps.CONTROLS_COUNT}
                            deviceSerial={deviceSerial}
                            interval={this.props.interval}
                            startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                            endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}
                            modacMacAddr={(this.props.selectedArea !== undefined && this.props.selectedArea.levelType === LevelType.MODAC) ? this.props.selectedArea.id : undefined}/>

                    </div>

                    <div key={"average_controls_duration"} data-grid={{ w: 3, h: 1, x: 3, y: 0, minW: 2, maxH:1 }}>
                        <Card infoType={CardProps.AVERAGE_CONTROLS_DURATION}
                              deviceSerial={deviceSerial}
                              interval={this.props.interval}
                              startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                              endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}
                              modacMacAddr={(this.props.selectedArea !== undefined && this.props.selectedArea.levelType === LevelType.MODAC) ? this.props.selectedArea.id : undefined}/>
                    </div>

                    <div key={"nb_alarms"} data-grid={{ w: 3, h: 1, x: 6, y: 0, minW: 2, maxH:1  }}>
                        <Card infoType={CardProps.CONTROLS_WITH_ALARM_COUNT}
                              deviceSerial={deviceSerial}
                              interval={this.props.interval}
                              startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                              endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}
                              modacMacAddr={(this.props.selectedArea !== undefined && this.props.selectedArea.levelType === LevelType.MODAC) ? this.props.selectedArea.id : undefined}/>
                    </div>

                    <div key={"alarm_percent"} data-grid={{ w: 3, h: 1, x: 9, y: 0, minW: 2, maxH:1 }}>
                        <Card infoType={CardProps.ALARM_PERCENT}
                              deviceSerial={(this.props.selectedArea !== undefined && this.props.selectedArea.levelType === LevelType.SAS) ? this.props.selectedArea.id : undefined}
                              interval={this.props.interval}
                              startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                              endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}/>
                    </div>


                    <div className={classes.ChartContainer} key={"repartition_graph"} data-grid={{ w: 12, h: 3, x: 0, y: 1, minW: 3, minH: 3 }}>
                        <label><Trans>ControlsDurationRepartition</Trans> (%)</label>
                        <div className={classes.ChartTitleSeparator}/>
                        <div className={classes.ChartGraphContainer}>

                            <Graph graphType={GraphType.RADIOACTIVITY_REPARTITION}
                                   deviceSerial={deviceSerial}
                                   interval={this.props.interval}
                                   startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                                   endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}
                                   modacMacAddr={(this.props.selectedArea !== undefined && this.props.selectedArea.levelType === LevelType.MODAC) ? this.props.selectedArea.id : undefined}/>
                        </div>
                    </div>

                    <div className={classes.ChartContainer} key={"controls_duration_graph"} data-grid={{ w: 12, h: 3, x: 0, y: 4, minW: 3, minH: 3 }}>
                        <label><Trans>AverageControlsDuration</Trans> (sec)</label>
                        <div className={classes.ChartTitleSeparator}/>
                        <div className={classes.ChartGraphContainer}>
                            <Graph
                                graphType={GraphType.CONTROLS_DURATION_EVOLUTION}
                                deviceSerial={deviceSerial}
                                interval={this.props.interval}
                                startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                                endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}
                                modacMacAddr={(this.props.selectedArea !== undefined && this.props.selectedArea.levelType === LevelType.MODAC) ? this.props.selectedArea.id : undefined}/>
                        </div>
                    </div>



                    <div className={classes.ChartContainer} key={"repartition_graph_radioactivity"} data-grid={{ w: 6, h: 3, x: 0, y: 7, minW: 3, minH: 3 }}>
                        <label><Trans>RadiationLevel</Trans> (BETA) {this.state.measuresUnit !== undefined ? `(${this.state.measuresUnit})` : undefined}</label>
                        <div className={classes.ChartTitleSeparator}/>
                        <div className={classes.ChartGraphContainer}>
                            <Graph
                                graphType={GraphType.RADIOACTIVITY_LEVEL_EVOLUTION}
                                deviceSerial={deviceSerial}
                                interval={this.props.interval}
                                startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                                endDatePeriodTimestamp={this.props.endDatePeriodTimestamp}
                                modacMacAddr={(this.props.selectedArea !== undefined && this.props.selectedArea.levelType === LevelType.MODAC) ? this.props.selectedArea.id : undefined}/>
                        </div>
                    </div>

                    <div className={classes.ChartContainer} key={"repartition_graph_radioactivity_during_controls"} data-grid={{ w: 6, h: 3, x: 6, y: 7, minW: 3, minH: 3 }}>
                        <label><Trans>RadiationLevelDuringControls</Trans> (BETA) {this.state.measuresUnit !== undefined ? `(${this.state.measuresUnit})` : undefined}</label>
                        <div className={classes.ChartTitleSeparator}/>
                        <div className={classes.ChartGraphContainer}>
                            <Graph
                                graphType={GraphType.RADIOACTIVITY_LEVEL_DURING_CONTROLS_EVOLUTION}
                                deviceSerial={deviceSerial}
                                interval={this.props.interval}
                                startDatePeriodTimestamp={this.props.startDatePeriodTimestamp}
                                endDatePeriodTimestamp={this.props.endDatePeriodTimestamp} probe={PROBE_NUMBER.PROBE_1} modacMacAddr={(this.props.selectedArea !== undefined && this.props.selectedArea.levelType === LevelType.MODAC) ? this.props.selectedArea.id : undefined}/>
                        </div>
                    </div>
                </ResponsiveReactGridLayout>

            </div>
        )
    }
}

const saveToLS = (key, value) => {
    if (localStorage) {
        localStorage.setItem("rgl-measures",
            JSON.stringify({
                [key]: value
            })
        );
    }
}

const getFromLS = (key) => {
    let ls = {};
    if (localStorage) {
        try {
            ls = JSON.parse(localStorage.getItem("rgl-measures")) || {};
        } catch (e) {
            /*Ignore*/
        }
    }
    return ls[key];
}

export default DashboardMeasures
