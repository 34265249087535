import i18n from "../../../i18n"

export const getDataset = (dataParam) => {
    const buckets = dataParam.aggregations.date.buckets
    const lowDataset = {backgroundColor: "#FFDE03", label: i18n.t("Low"), data: []}
    const highDataset = {backgroundColor: "#ffaa33", label: i18n.t("High"), data: []}
    const saturateDataset = {backgroundColor: "#ff2709", label: i18n.t("Saturate"), data: []}
    let dates = []
    buckets.forEach(it => {
        const date = new Date(it.key_as_string)
        dates.push(date)

        lowDataset.data.push(
            {
                x: date,
                y: it.alarm.buckets.low.max_value.value !== null ? it.alarm.buckets.low.max_value.value.toFixed(3) : null
            }
        )

        highDataset.data.push(
            {
                x: date,
                y: it.alarm.buckets.high.max_value.value !== null ? it.alarm.buckets.high.max_value.value.toFixed(3) : null
            }
        )

        saturateDataset.data.push(
            {
                x: date,
                y: it.alarm.buckets.saturate.max_value.value !== null ? it.alarm.buckets.saturate.max_value.value.toFixed(3) : null
            }
        )

    })

    return {
        datasets: [lowDataset, highDataset, saturateDataset],
        dates: dates,
    }
}
